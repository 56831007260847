import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { MarqueeType, MerchantPortalStyleShopPages, NavBarClickType } from '@/Templates/enums/templateEnums';
import { IItemViewModel, INavBarOptionDto, IPaginationResponse, MerchantMarqueeSettingViewModel } from '@/Templates/interfaces/templatesInterfaces';
import { ItemCardTemplateProps, NavBarTemplateProps, PagesTemplateProps, TemplateProps } from '@/Templates/TemplateProps';
import { Col, Empty, Row, Select, Tabs } from 'antd';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import StyleConfigSelector from './StyleConfigSelector';

export interface ShopPreviewProps extends TemplateProps {
    selectedComponentStyle: number;
    selectedPageStyle: number;
    onComponentConfigFieldsChange: (fields: Array<{ key: string, label: string, type: string, defaultValue: string }>) => void;
    onPageConfigFieldsChange: (fields: Array<{ key: string, label: string, type: string, defaultValue: string }>) => void;
}

export interface IShopStyleSelectorRef {
    onRefresh: () => void;
}

const testItem: IItemViewModel = {
    id: BigInt(1),
    name: "测试商品",
    title: "高品质测试商品",
    briefly: "这是一个用于测试的商品项目",
    description: "这是一个详细的商品描述，用于测试商品展示功能。它包含了商品的各种特性和优点。",
    createdDate: new Date().toISOString(),
    alterDate: new Date().toISOString(),
    isDelete: false,
    itemConverImagePaths: [
        "https://storage.googleapis.com/microshop-images-bucket/system/TestItem.jpg",
    ],
    itemTags: [
        {
            id: BigInt(1),
            merchantId: BigInt(1),
            name: "测试标签",
            isDeleted: false
        }
    ],
    minPrice: 99.99,
    maxPrice: 199.99,
    isOnShelf: true
};

const testPaginationItems: IPaginationResponse<IItemViewModel[]> = {
    totalCount: 10,
    totalPage: 1,
    data: [
        {
            id: BigInt(1),
            name: "测试商品",
            title: "高品质测试商品",
            briefly: "这是一个用于测试的商品项目",
            description: "这是一个详细的商品描述，用于测试商品展示功能。它包含了商品的各种特性和优点。",
            createdDate: new Date().toISOString(),
            alterDate: new Date().toISOString(),
            isDelete: false,
            itemConverImagePaths: [
                "https://storage.googleapis.com/microshop-images-bucket/system/TestItem.jpg",
            ],
            itemTags: [
                {
                    id: BigInt(1),
                    merchantId: BigInt(1),
                    name: "测试标签",
                    isDeleted: false
                }
            ],
            minPrice: 99.99,
            maxPrice: 199.99,
            isOnShelf: true
        },
    ]
};

const testMenuItems: INavBarOptionDto[] = [
    {
        id: BigInt(1),
        merchantId: BigInt(1),
        name: "首页",
        type: NavBarClickType.None,
        extraInfo: "/",
        isDeleted: false,
        orderIndex: 1,
        childrenIds: [],
    },
    {
        id: BigInt(2),
        merchantId: BigInt(1),
        name: "商品分类",
        type: NavBarClickType.None,
        extraInfo: "",
        isDeleted: false,
        orderIndex: 2,
        childrenIds: [BigInt(5), BigInt(6)],
        children: [
            {
                id: BigInt(5),
                merchantId: BigInt(1),
                name: "服装",
                type: NavBarClickType.ItemSearch,
                extraInfo: "clothing",
                isDeleted: false,
                orderIndex: 1,
                childrenIds: [],
            },
            {
                id: BigInt(6),
                merchantId: BigInt(1),
                name: "配饰",
                type: NavBarClickType.ItemSearch,
                extraInfo: "accessories",
                isDeleted: false,
                orderIndex: 2,
                childrenIds: [],
            },
        ],
    },
    {
        id: BigInt(3),
        merchantId: BigInt(1),
        name: "关于我们",
        type: NavBarClickType.None,
        extraInfo: "/about",
        isDeleted: false,
        orderIndex: 3,
        childrenIds: [],
    },
    {
        id: BigInt(4),
        merchantId: BigInt(1),
        name: "联系我们",
        type: NavBarClickType.None,
        extraInfo: "/contact",
        isDeleted: false,
        orderIndex: 4,
        childrenIds: [],
    },
];

const testMainMarqueeViewModel: MerchantMarqueeSettingViewModel = {
    id: BigInt(1),
    merchantId: BigInt(1),
    imagePaths: [
        "https://storage.googleapis.com/microshop-images-bucket/system/TestItem.jpg",
    ],
    imageIds: [],
    externalLinks: [],
    createdDate: new Date().toISOString(),
    type: MarqueeType.MerchantMain,
};

const ShopPreview = forwardRef<IShopStyleSelectorRef, ShopPreviewProps>(({
    merchantId,
    config,
    onComponentConfigFieldsChange,
    onPageConfigFieldsChange,
    messageApi
}, ref) => {
    const { translate } = useContext(TranslationContext);
    const { deviceType, merchantPortalOptionSetting, merchantPortalStyleSettings } = useContext(GlobalContext);
    const [ComponentTemplateComponent, setComponentTemplateComponent] = useState<React.ComponentType<TemplateProps> | null>(null);
    const [PageTemplateComponent, setPageTemplateComponent] = useState<React.ComponentType<PagesTemplateProps> | null>(null);
    const [componentProps, setComponentProps] = useState<((NavBarTemplateProps | ItemCardTemplateProps) & { isCentered?: boolean }) | null>(null);
    const [selectedPageType, setSelectedPageType] = useState<MerchantPortalStyleShopPages>(MerchantPortalStyleShopPages.HomePage);
    const [selectedComponentType, setSelectedComponentType] = useState<'NavBar' | 'ItemCard'>('NavBar');
    const [selectedComponentStyle, setSelectedComponentStyle] = useState(0);
    const [selectedPageStyle, setSelectedPageStyle] = useState(0);
    const [componentStyleConfig, setComponentStyleConfig] = useState<Record<string, string>>({});
    const [pageStyleConfig, setPageStyleConfig] = useState<Record<string, string>>({});
    const [componentConfigFields, setComponentConfigFields] = useState<Array<any>>([]);
    const [pageConfigFields, setPageConfigFields] = useState<Array<any>>([]);

    const loadComponentTemplate = async () => {
        try {
            const componentModule = await import(`@/Templates/Shop/Components/${selectedComponentType}/Template_${selectedComponentStyle}.tsx`);
            setComponentTemplateComponent(() => componentModule.default);
            if (componentModule.getConfigFields) {
                const fields = componentModule.getConfigFields();
                onComponentConfigFieldsChange(fields);
            } else {
                onComponentConfigFieldsChange([]);
            }
            console.log('loadComponentTemplate success');
        } catch (error) {
            console.error('加载组件模板失败:', error);
            setComponentTemplateComponent(null);
            onComponentConfigFieldsChange([]);
        }
    };

    const loadPageTemplate = async () => {
        try {
            console.log('selectedPageType', selectedPageType);
            const pageModule = await import(`@/Templates/Shop/Pages/${selectedPageType}/Template_${selectedPageStyle}.tsx`);
            setPageTemplateComponent(() => pageModule.default);
            if (pageModule.getConfigFields) {
                const fields = pageModule.getConfigFields();
                onPageConfigFieldsChange(fields);
            } else {
                onPageConfigFieldsChange([]);
            }
            console.log('loadPageTemplate success');
        } catch (error) {
            console.error('加载页面模板失败:', error);
            setPageTemplateComponent(null);
            onPageConfigFieldsChange([]);
        }
    };

    const loadComponentProps = async () => {
        try {
            switch (selectedComponentType) {
                case 'NavBar':
                    setComponentProps({
                        merchantId,
                        config,
                        BASE_PATH: '/base-path',
                        menuItems: testMenuItems,
                        isHideNavBar: false,
                        merchantPortalOptionSetting: merchantPortalOptionSetting,
                        navBarHeight: 60,
                        deviceType: deviceType,
                        shoppingCartItemCount: 0,
                        isLogin: false,
                        translate,
                        isPreviewMode: true,
                        portalOptionSettingViewModel: merchantPortalOptionSetting,
                        portalStyleSettings: merchantPortalStyleSettings,
                        isCentered: false,
                    } as NavBarTemplateProps & { isCentered: boolean });
                    break;
                case 'ItemCard':
                    setComponentProps({
                        item: testItem,
                        isloading: false,
                        deviceType: deviceType,
                        isPreviewMode: true,
                        merchantId,
                        isCentered: true
                    } as ItemCardTemplateProps & { isCentered: boolean });
                    break;
                default:
                    setComponentProps(null);
            }
        } catch (error) {
            console.error('加载导航栏属性失败:', error);
            setComponentProps(null);
        }
    };

    useEffect(() => {
        loadComponentTemplate();
        loadPageTemplate();
        loadComponentProps();
    }, [selectedComponentStyle, selectedPageStyle, selectedComponentType, selectedPageType]);

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            loadComponentTemplate();
            loadPageTemplate();
            loadComponentProps();
        }
    }));

    const handleComponentStyleChange = (value: number) => {
        // 实现组件样式更改逻辑
        setSelectedComponentStyle(value);
    };

    const handlePageStyleChange = (value: number) => {
        // 实现页面样式更改逻辑
        setSelectedPageStyle(value);
    };

    const handleComponentConfigChange = (key: string, value: string) => {
        setComponentStyleConfig(prev => ({ ...prev, [key]: value }));
    };

    const handlePageConfigChange = (key: string, value: string) => {
        setPageStyleConfig(prev => ({ ...prev, [key]: value }));
    };

    const handleComponentSave = () => {
        // 实现组件样式保存逻辑
        console.log('handleComponentSave');
    };

    const handlePageSave = () => {
        // 实现页面样式保存逻辑
        console.log('handlePageSave');
    };

    const componentStyleOptions = [
        { value: 0, label: translate('Style 1') },
        { value: 1, label: translate('Style 2') },
    ];

    const pageStyleOptions = [
        { value: 0, label: translate('Style 1') },
        { value: 1, label: translate('Style 2') },
    ];

    const items = [
        {
            key: 'Components',
            label: translate('Components'),
            children: (
                <div>
                    <Select
                        style={{ width: 200, marginBottom: 16 }}
                        value={selectedComponentType}
                        onChange={(value) => setSelectedComponentType(value)}
                    >
                        <Select.Option value="Navbars">{translate('Navbars')}</Select.Option>
                        <Select.Option value="ItemCards">{translate('ItemCards')}</Select.Option>
                    </Select>
                    <div className="preview-container" style={{
                        border: '1px solid #ccc',
                        height: '500px',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch'
                    }}>
                        {componentProps && ComponentTemplateComponent ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: componentProps.isCentered ? 'center' : 'flex-start',
                                alignItems: componentProps.isCentered ? 'center' : 'flex-start',
                                height: '100%',
                                width: '100%'
                            }}>
                                <ComponentTemplateComponent {...componentProps} />
                            </div>
                        ) : (
                            <Empty description={translate('No component template')} />
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <StyleConfigSelector
                            selectedStyle={selectedComponentStyle}
                            styleConfig={componentStyleConfig}
                            configFields={componentConfigFields}
                            onStyleChange={handleComponentStyleChange}
                            onConfigChange={handleComponentConfigChange}
                            onSave={handleComponentSave}
                            styleOptions={componentStyleOptions}
                        />
                    </div>
                </div>
            ),
        },
        {
            key: 'pages',
            label: translate('Pages'),
            children: (
                <div>
                    <Select
                        style={{ width: 200, marginBottom: 16 }}
                        value={selectedPageType}
                        onChange={(value: MerchantPortalStyleShopPages) => setSelectedPageType(value)}
                    >
                        <Select.Option value="HomePage">{translate('HomePage')}</Select.Option>
                        <Select.Option value="SearchPage">{translate('SearchPage')}</Select.Option>
                    </Select>
                    <div className="preview-container" style={{
                        border: '1px solid #ccc',
                        height: '500px',
                        overflow: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {PageTemplateComponent ? (
                            <PageTemplateComponent
                                translate={translate}
                                messageApi={messageApi}
                                merchantId={merchantId}
                                config={config}
                                portalOptionSettingViewModel={merchantPortalOptionSetting}
                                portalStyleSettings={merchantPortalStyleSettings}
                                deviceType={deviceType}
                                isPreviewMode={true}
                                items={testPaginationItems}
                                mainMarqueeViewModel={testMainMarqueeViewModel}
                            />
                        ) : (
                            <Empty description={translate('No page template')} />
                        )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <StyleConfigSelector
                            selectedStyle={selectedPageStyle}
                            styleConfig={pageStyleConfig}
                            configFields={pageConfigFields}
                            onStyleChange={handlePageStyleChange}
                            onConfigChange={handlePageConfigChange}
                            onSave={handlePageSave}
                            styleOptions={pageStyleOptions}
                        />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Row>
            <Col span={24}>
                <h3 className="flex justify-center">{translate('Preview')}</h3>
                <Tabs items={items} />
            </Col>
        </Row>
    );
});

export default ShopPreview;