import { IAlterPortalStyleRequest } from '@/interfaces/Requests/Merchant';
import { useAlterPortalStyleApi } from '@/lib/api/merchants';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { MerchantPortalStyleSettingType } from '@/Templates/enums/templateEnums';
import { Col, Flex, Row } from 'antd';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useMutation } from 'react-query';
import { PortalSettingPageContext } from '../Contexts/PortalSettingPageContext';
import LandingPagePreview from './LandingPagePreview';
import StyleConfigSelector from './StyleConfigSelector';

export interface ILandingPageStyleSelectorProp {

}
export interface ILandingPageStyleSelectorRef {
    onRefresh: () => void;
}

const LandingPageStyleSelector = React.memo(React.forwardRef((props: ILandingPageStyleSelectorProp, ref: React.ForwardedRef<ILandingPageStyleSelectorRef | undefined>) => {
    const { abortController } = useContext(PortalSettingPageContext);
    const { merchantPortalOptionSetting, messageApi, merchantId, deviceType, merchantPortalStyleSettings, getMerchantPortalStyleSettings } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [selectedStyle, setSelectedStyle] = useState(merchantPortalStyleSettings?.filter(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'LandingPage')[0]?.style || 0);
    const [styleConfig, setStyleConfig] = useState<Record<string, string>>(merchantPortalStyleSettings?.filter(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'LandingPage')[0]?.styleSetting || {});
    const [configFields, setConfigFields] = useState<Array<any>>([]);

    const handleStyleChange = (value: number) => {
        setSelectedStyle(value);
        // Reset styleConfig to default values when changing style
        const defaultConfig = configFields.reduce((acc, field) => {
            acc[field.key] = field.defaultValue;
            return acc;
        }, {} as Record<string, string>);
        setStyleConfig(defaultConfig);
    };

    const handleConfigChange = (key: string, value: string) => {
        setStyleConfig(prev => ({ ...prev, [key]: value }));
    };

    const handleSave = () => {
        alterPortalStyleApi.mutate({
            type: MerchantPortalStyleSettingType.Pages,
            name: 'LandingPage',
            style: selectedStyle,
            styleSetting: styleConfig,
        });
    };

    const styleOptions = [
        { value: 0, label: translate('Style 1') },
        { value: 1, label: translate('Style 2') },
    ];

    const alterPortalStyleApi = useMutation(async (request: IAlterPortalStyleRequest) => useAlterPortalStyleApi(request), {
        onSuccess: () => {
            getMerchantPortalStyleSettings.mutate({
                merchantId: merchantId!,
            });
            messageApi.success(translate('Operation Success'));
        },
        onError: () => {
            messageApi.error(translate('Operation Failed'));
        }
    });

    useEffect(() => {
        if (getMerchantPortalStyleSettings.data?.result) {
            const { style, styleSetting } = getMerchantPortalStyleSettings.data.result.filter(x => x.type === MerchantPortalStyleSettingType.Pages && x.name === 'LandingPage')[0] || { style: 1, styleSetting: {} };

            setSelectedStyle(style);
            setStyleConfig(styleSetting);
        }
    }, [getMerchantPortalStyleSettings.data]);

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            getMerchantPortalStyleSettings.mutate({
                merchantId: merchantId!,
                signal: abortController.current.signal
            });
        }
    }));

    useEffect(() => {
        return () => {
            abortController.current.abort();
            abortController.current = new AbortController();
        };
    }, []);

    if (getMerchantPortalStyleSettings.isLoading || alterPortalStyleApi.isLoading) {
        return <LoadingComponent></LoadingComponent>
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <LandingPagePreview
                    messageApi={messageApi}
                    translate={translate}
                    selectedStyle={selectedStyle}
                    config={styleConfig}
                    merchantId={merchantId!}
                    portalOptionSettingViewModel={merchantPortalOptionSetting}
                    portalStyleSettings={merchantPortalStyleSettings}
                    onConfigFieldsChange={setConfigFields}
                />
            </Col>
            <Col span={24}>
                <Flex justify="center">
                    <StyleConfigSelector
                        selectedStyle={selectedStyle}
                        styleConfig={styleConfig}
                        configFields={configFields}
                        onStyleChange={handleStyleChange}
                        onConfigChange={handleConfigChange}
                        onSave={handleSave}
                        styleOptions={styleOptions}
                    />
                </Flex>
            </Col>
        </Row>
    );
}));

export default LandingPageStyleSelector;