import { IAlterMerchantThirdPartyPaymentSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyPaymentSettingViewModel } from "@/interfaces/Responses/Responses";
import { useAlterMerchantThirdPartyPaymentSettingApi, useGetBackOfficeMerchantThirdPartyPaymentSettingViewModelsApi } from "@/lib/api/thirdPartyPayments";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { ExclamationCircleOutlined, PlusOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import { Button, Modal, Popover, Switch, Table, Tag } from "antd";
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useMutation } from "react-query";
import { ThirdPartyPageContext } from "../../Contexts/ThirdPartyPageContext";
import AddPayment, { IAddPaymentSettingRef } from "./AddPayment";
import EditPayment, { IEditPaymentSettingRef } from "./EditPayment";

export interface IPaymentSettingListProps {

}

export interface IPaymentSettingListRef {
    onRefresh: () => void;
}

const PaymentList = React.forwardRef((props: IPaymentSettingListProps, ref: React.ForwardedRef<IPaymentSettingListRef | undefined>) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const addRef = useRef<IAddPaymentSettingRef>();
    const editRef = useRef<IEditPaymentSettingRef>();
    const { abortController } = useContext(ThirdPartyPageContext);
    const getTagContent = (type) => {
        switch (type) {
            case 'cancel':
                switch (i18nLanguage) {
                    case 'zh_TW':
                        return {
                            title: '支援取消資訊',
                            content: '此訂單可使用原始付款方式設定進行取消。'
                        };
                    case 'en_US':
                    default:
                        return {
                            title: 'Support Cancel Information',
                            content: 'This order can be canceled using the original payment method settings.'
                        };
                };
            case 'refund':
                switch (i18nLanguage) {
                    case 'zh_TW':
                        return {
                            title: '支援退款資訊',
                            content: '退款可使用原始付款方式設定進行處理。退款將根據供應商提供的程序進行處理。'
                        };
                    case 'en_US':
                    default:
                        return {
                            title: 'Support Refund Information',
                            content: 'Refunds can be processed using the original payment method settings. The refund will be processed according to the procedure provided by the vendor.'
                        };
                };
            default:
                return { title: '', content: '' };
        }
    };
    const [merchantThirdPartySettings, setMerchantThirdPartySettings] = useState<IBackOfficeMerchantThirdPartyPaymentSettingViewModel[]>([]);

    const { data, mutate, isLoading } = useMutation(async () => await useGetBackOfficeMerchantThirdPartyPaymentSettingViewModelsApi(abortController.current.signal), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setMerchantThirdPartySettings(response.result || []);
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { mutate: alterMutate, isLoading: alterMutateIsLoading } = useMutation(async (request: IAlterMerchantThirdPartyPaymentSettingRequest) => await useAlterMerchantThirdPartyPaymentSettingApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                refresh();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const [selectedEditSetting, setSelectedEditSetting] = useState<IBackOfficeMerchantThirdPartyPaymentSettingViewModel | undefined>(undefined);
    const handleToggleDisable = (record: IBackOfficeMerchantThirdPartyPaymentSettingViewModel, isDisable: boolean) => {
        alterMutate({
            settingId: record.thirdPartySettingId,
            setting: record.merchantSettings,
            isDisabled: isDisable
        });
    };
    const [openAddSetting, setOpenAddSetting] = useState(false);

    const columns = [
        {
            title: translate('Provider Name'),
            dataIndex: 'providerName',
            sorter: (a: IBackOfficeMerchantThirdPartyPaymentSettingViewModel, b: IBackOfficeMerchantThirdPartyPaymentSettingViewModel) => a.providerName.localeCompare(b.providerName),
            key: 'providerName',
            render: (text: string, record: IBackOfficeMerchantThirdPartyPaymentSettingViewModel) => (
                <span>
                    {!record.isReleaseNow && (
                        <Tag color="orange" icon={<ExclamationCircleOutlined />}>
                            {translate('Under Maintenance')}
                        </Tag>
                    )}
                    {text}
                    {
                        record.isSupportedCancel && (

                            <Tag color="red" style={{ cursor: 'pointer', margin: '5px' }}>
                                <Popover
                                    content={getTagContent('cancel').content}
                                    title={getTagContent('cancel').title}
                                >
                                    <QuestionCircleTwoTone />
                                </Popover>
                                {translate('Support Cancel')}
                            </Tag>

                        )
                    }
                    {
                        record.isSupportedRefund && (

                            <Tag color="purple" style={{ cursor: 'pointer', margin: '5px' }}>
                                <Popover
                                    content={getTagContent('refund').content}
                                    title={getTagContent('refund').title}
                                >
                                    <QuestionCircleTwoTone />
                                </Popover>
                                {translate('Support Refund')}
                            </Tag>
                        )
                    }
                </span >
            ),
        },
        {
            title: translate('Enable'),
            key: 'isDisable',
            render: (record: IBackOfficeMerchantThirdPartyPaymentSettingViewModel) => (
                <Switch
                    loading={isLoading || alterMutateIsLoading}
                    checked={!record.isDisable}
                    onChange={(checked) => handleToggleDisable(record, !checked)}
                />
            ),
        },
        {
            title: translate('Operation'),
            key: 'action',
            render: (record: IBackOfficeMerchantThirdPartyPaymentSettingViewModel) => (
                <Button onClick={() => handleEdit(record)}>
                    {translate('Edit')}
                </Button>
            ),
        },
    ];

    const handleEdit = (record: IBackOfficeMerchantThirdPartyPaymentSettingViewModel) => {
        setSelectedEditSetting(record);
    };

    const refresh = () => {
        mutate();
    };

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            refresh();
        }
    }));

    useEffect(() => {
        refresh();
    }, []);

    return (
        <>
            <Table
                loading={isLoading}
                columns={columns}
                pagination={{ position: ['bottomCenter'] }}
                dataSource={merchantThirdPartySettings}
                footer={() => (
                    <Button
                        type="dashed"
                        onClick={() => setOpenAddSetting(true)}
                        block
                        icon={<PlusOutlined />}
                    >
                        {translate('Add new setting')}
                    </Button>
                )}
                rowKey={record => record.id.toString()}
                bordered
            />
            <Modal
                open={selectedEditSetting !== undefined}
                afterOpenChange={(open) => {
                    if (!open)
                        editRef.current?.onClose();
                    else
                        editRef.current?.onOpen(selectedEditSetting!);
                }}
                onCancel={() => setSelectedEditSetting(undefined)}
                footer={null}
            >
                <EditPayment
                    ref={editRef}
                    onRefresh={() => refresh()}
                    onClose={() => setSelectedEditSetting(undefined)}
                />
            </Modal>
            <Modal
                open={openAddSetting}
                afterOpenChange={(open) => {
                    if (open) addRef.current?.refresh();
                }}
                onCancel={() => setOpenAddSetting(false)}
                footer={null}
            >
                <AddPayment
                    ref={addRef}
                    ownRecords={merchantThirdPartySettings}
                    onRefresh={() => refresh()}
                    onClose={() => setOpenAddSetting(false)}
                />
            </Modal>
        </>
    );
});

export default PaymentList;