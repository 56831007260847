import { IConfirmMerchantApplyRequest as IMerchantApplyRequest } from "@/interfaces/Requests/Requests";
import { IMerchantApply } from "@/interfaces/Responses/SystemManager";
import { useConfirmMerchatApplyApi, useGetMerchantAppliesApi, useRejectMerchantApplyApi } from "@/lib/api/systemManager";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Empty, Space, Table } from "antd";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";

const SystemManagePage = () => {
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const { data, mutate: getMerchantAppliesMutate } = useMutation(async () => await useGetMerchantAppliesApi());
    const { mutate, isLoading: confirmLoading } = useMutation(async (request: IMerchantApplyRequest) => await useConfirmMerchatApplyApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success('success');
                getMerchantAppliesMutate(); // Refresh the data
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const { mutate: rejectMutate, isLoading: rejectLoading } = useMutation(async (request: IMerchantApplyRequest) => await useRejectMerchantApplyApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success('success');
                getMerchantAppliesMutate(); // Refresh the data
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const isLoading = confirmLoading || rejectLoading;

    useEffect(() => {
        getMerchantAppliesMutate();
    }, []);

    const handleConfirm = (userId: string) => {
        mutate({ userId: userId });
    };

    const handleReject = (userId: string) => {
        rejectMutate({ userId: userId });
    };

    const columns = [
        {
            title: translate('Ticket number'),
            render: (record: IMerchantApply) => record.id.toString(),
        },
        {
            title: translate('UserId'),
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: translate('UserName'),
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: translate('Email'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: translate('PhoneNumber'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: translate('Create time'),
            dataIndex: 'createTime',
            key: 'createTime',
            render: (text: string) => new Date(text).toLocaleString(),
        },
        {
            title: translate('Operation'),
            key: 'actions',
            render: (text: string, record: IMerchantApply) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        onClick={() => handleConfirm(record.userId.toString())}
                        loading={isLoading}>
                        {translate('Confirm')}
                    </Button>
                    <Button
                        type="primary"
                        danger
                        onClick={() => handleReject(record.userId.toString())}
                        loading={isLoading}>
                        {translate('Reject')}
                    </Button>
                </Space>
            ),
        },
    ];

    if (!data || !data.result?.length) {
        return <Empty />;
    }

    return (
        <Table
            dataSource={data.result}
            columns={columns}
            rowKey="id"
            loading={isLoading}
            bordered
        />
    );
};

export default SystemManagePage;
