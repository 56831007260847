import { DeviceType } from "@/Templates/enums/templateEnums";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Image, Popover } from "antd";
import React, { useContext, useMemo } from "react";
import MessageGroupPortalMiniIcon from '../../../../../assets/MessageGroupPortalMiniIcon.png';

export interface InformationPopoversProps {
    module: string;
    parameter: string;
}

interface ContentType {
    zh_TW: React.ReactNode;
    en_US: React.ReactNode;
}

const InformationPopovers: React.FC<InformationPopoversProps> = ({ module, parameter }) => {
    const { deviceType } = useContext(GlobalContext);
    const { i18nLanguage } = useContext(TranslationContext);
    const maxWidth = deviceType === DeviceType.Mobile ? '100vw' : '50vw';

    const contentMap: Record<string, Record<string, ContentType>> = {
        MessageGroup: {
            ShowJoinIcon: {
                zh_TW: (
                    <div>
                        <ol>
                            <li>此參數說明：</li>
                            <li>影響前端是否顯示迷你圖示，點擊後串接至第三方平台社群功能。</li>
                            <Image src={MessageGroupPortalMiniIcon} alt="MessageGroupPortalMiniIcon" />
                        </ol>
                    </div>
                ),
                en_US: (
                    <div>
                        <ol>
                            <li>Parameter description:</li>
                            <li>Affects whether the frontend displays a mini icon, which when clicked, connects to the third-party platform's community features.</li>
                            <Image src={MessageGroupPortalMiniIcon} alt="MessageGroupPortalMiniIcon" />
                        </ol>
                    </div>
                )
            },
            // 可以在這裡添加更多 MessageGroup 模組的參數
        },
        // 可以在這裡添加更多模組
    };

    const content = useMemo(() => {
        const moduleContent = contentMap[module];
        if (moduleContent) {
            const parameterContent = moduleContent[parameter];
            if (parameterContent) {
                return parameterContent[i18nLanguage as keyof ContentType] || parameterContent.zh_TW;
            }
        }
        return null;
    }, [module, parameter, i18nLanguage]);

    if (!content) {
        return null;
    }

    return (
        <Popover
            trigger="hover"
            arrow={false}
            content={
                <div style={{
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    padding: '16px'
                }}>
                    {content}
                </div>
            }
            overlayStyle={{ maxWidth }}
            overlayInnerStyle={{
                maxHeight: '80vh',
                overflow: 'hidden'
            }}
        >
            <QuestionCircleTwoTone />
        </Popover>
    );
};

export default InformationPopovers;