import { IBatchOrderRequest, IGetBackOfficeOrderViewModelRequest, IManualAlterOrderRequest } from '@/interfaces/Requests/Requests';
import {
    IOperationResult,
    IOperationResultT, IOrder, IOrderOperationHistory, IPaginationResponse
} from '../../interfaces/Responses/Responses';
import useAPI from '../customHooks/useAPI';

export const useGetMerchantOrdersApi = (request: IGetBackOfficeOrderViewModelRequest, signal?: AbortSignal) => useAPI<IOperationResultT<IPaginationResponse<IOrder[]>>>(`${process.env.BASE_API_URL}/api/Order/GetMerchantOrders`, {
    method: 'GET',
    body: request,
    signal: signal
});

export const useManualAlterOrderApi = (request: IManualAlterOrderRequest, signal?: AbortSignal) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Order/ManualAlterOrder`, {
    method: 'POST',
    body: request,
    signal: signal
});

export const useGetOrderOperationHistoriesApi = (orderId: BigInt, signal?: AbortSignal) => useAPI<IOperationResultT<IOrderOperationHistory[]>>(`${process.env.BASE_API_URL}/api/Order/GetOrderOperationHistories?orderId=${orderId.toString()}`, {
    signal: signal
});

export const useBatchOrderCancelApi = (request: IBatchOrderRequest, signal?: AbortSignal) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Order/BatchOrderCancel`, {
    method: 'POST',
    body: request,
    signal: signal
});

export const useBatchOrderConfirmApi = (request: IBatchOrderRequest, signal?: AbortSignal) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Order/BatchOrderConfirm`, {
    method: 'POST',
    body: request,
    signal: signal
});