import { MerchantCheckoutType } from '@/Templates/enums/templateEnums';
import { IAlterMerchantPortalSettingRequest } from '@/interfaces/Requests/Requests';
import { useAlterMerchantIconApi, useAlterMerchantPortalSettingApi } from '@/lib/api/merchants';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { PlusOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Col, Flex, Form, Input, InputNumber, Popover, Row, Select, Upload } from 'antd';
import Title from 'antd/es/typography/Title';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import { PortalSettingPageContext } from '../Contexts/PortalSettingPageContext';

export interface IPortalGlobalSystemSettingProp { }
export interface IPortalGlobalSystemSettingRef {
    onRefresh: () => void;
}

const PortalGlobalSystemSetting = React.memo(React.forwardRef((props: IPortalGlobalSystemSettingProp, ref: React.ForwardedRef<IPortalGlobalSystemSettingRef | undefined>) => {
    const queryClient = useQueryClient();
    const [form] = Form.useForm();
    const { merchantId, messageApi, merchantPortalOptionSetting, getMerchantPortalOptionSetting } = useContext(GlobalContext);
    const { supportedLanguages, translate, i18nLanguage } = useContext(TranslationContext);
    const { abortController } = useContext(PortalSettingPageContext);
    const [imageChanged, setImageChanged] = useState(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [alterRequest, setAlterRequest] = useState<IAlterMerchantPortalSettingRequest>({
        merchantName: merchantPortalOptionSetting?.merchantName,
        portalLanguage: merchantPortalOptionSetting?.portalLanguage,
        merchantCheckoutType: merchantPortalOptionSetting?.merchantCheckoutType,
        isEnableInovice: merchantPortalOptionSetting?.isEnableInovice,
        orderMinimumAmountLimit: merchantPortalOptionSetting?.orderMinimumAmountLimit,
        merchantId: merchantId
    } as IAlterMerchantPortalSettingRequest);

    useEffect(() => {
        if (merchantPortalOptionSetting?.merchantIconPath) {
            setFileList([{
                uid: uuidv4(),
                name: 'merchantIcon',
                status: 'done',
                url: merchantPortalOptionSetting.merchantIconPath
            }]);
        } else {
            setFileList([]);
        }
    }, [merchantPortalOptionSetting?.merchantIconPath]);

    const { mutateAsync: alterMerchantPortalSettingApiMutate, isLoading: alterIsloading } = useMutation(
        async (request: IAlterMerchantPortalSettingRequest) => await useAlterMerchantPortalSettingApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const { mutateAsync: useAlterMerchantIconApiMutate, isLoading: alterIconIsloading } = useMutation(
        async (request: FormData) => await useAlterMerchantIconApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    useEffect(() => {
        if (merchantPortalOptionSetting) {
            setAlterRequest({
                merchantName: merchantPortalOptionSetting.merchantName,
                portalLanguage: merchantPortalOptionSetting.portalLanguage,
                merchantCheckoutType: merchantPortalOptionSetting.merchantCheckoutType,
                isEnableInovice: merchantPortalOptionSetting.isEnableInovice,
                orderMinimumAmountLimit: merchantPortalOptionSetting.orderMinimumAmountLimit,
            });
        }
    }, [merchantPortalOptionSetting, merchantId]);

    useEffect(() => {
        form.setFieldsValue({
            merchantName: alterRequest.merchantName,
            portalLanguage: alterRequest.portalLanguage,
            merchantCheckoutType: alterRequest.merchantCheckoutType,
            isEnableInovice: alterRequest.isEnableInovice,
            orderMinimumAmountLimit: alterRequest.orderMinimumAmountLimit,
        });
    }, [alterRequest, form]);

    useImperativeHandle(ref, () => ({
        onRefresh() {
            getMerchantPortalOptionSetting.mutate({ merchantId: merchantId! });
        }
    }));

    const handleBeforeUpload = (file: RcFile) => {
        const newFile: UploadFile = {
            uid: uuidv4(),
            name: file.name,
            status: 'done',
            url: URL.createObjectURL(file),
            originFileObj: file
        };
        setFileList([newFile]);
        setImageChanged(true);
        return false; // Prevent auto-upload
    };

    const handleRemove = () => {
        setFileList([]);
        setImageChanged(true);
    };

    const handleSave = async () => {
        const iconUploadPromise = imageChanged && fileList.length > 0 && fileList[0].originFileObj
            ? new Promise<void>(async (resolve) => {
                const formData = new FormData();
                formData.append('Image', fileList[0].originFileObj as Blob, fileList[0].name);
                await useAlterMerchantIconApiMutate(formData);
                resolve();
            })
            : Promise.resolve();

        await Promise.all([
            iconUploadPromise,
            alterMerchantPortalSettingApiMutate(alterRequest)
        ]);

        getMerchantPortalOptionSetting.mutate({ merchantId: merchantId! });
        setImageChanged(false);
    };

    const MinimumAmountLimitPopover = () => {
        const content = i18nLanguage === 'zh_TW'
            ? "此欄位功能為買家需要達到最低金額才能下單"
            : "This field sets the Minimum order amount required for buyers to place an order";

        return (
            <Popover content={content} title={translate('Minimum order amount')}>
                <QuestionCircleTwoTone style={{ marginLeft: 8 }} />
            </Popover>
        );
    };

    return (
        merchantPortalOptionSetting &&
        <>
            <Row>
                <Col xs={{ span: 24 }} style={{ marginTop: '30px', marginBottom: '30px' }}>
                    <Flex justify='center'>
                        <Title level={5}>{translate('Merchant logo')}</Title>
                    </Flex>
                    <Flex justify='center'>
                        <Upload
                            key={merchantPortalOptionSetting.merchantIconPath || 'upload'}
                            listType="picture-card"
                            fileList={fileList}
                            beforeUpload={handleBeforeUpload}
                            onRemove={handleRemove}
                        >
                            {fileList.length >= 1 ? null : (
                                <div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>{translate('Upload')}</div>
                                </div>
                            )}
                        </Upload>
                    </Flex>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 24 }} style={{ marginTop: '30px', marginBottom: '30px' }}>
                    <Flex justify='center'>
                        <Form form={form}>
                            <Form.Item name='merchantName' label={translate('Merchant') + ' ' + translate('Name')}>
                                <Input onChange={(e) => setAlterRequest({ ...alterRequest, merchantName: e.target.value })} />
                            </Form.Item>
                            <Form.Item name='portalLanguage' label={translate('Portal default language')}>
                                <Select onChange={(value) => setAlterRequest({ ...alterRequest, portalLanguage: value })}>
                                    {supportedLanguages.map((language) =>
                                        <Select.Option key={language} value={language}>
                                            {translate(language)}
                                        </Select.Option>
                                    )}
                                </Select>
                            </Form.Item>
                            <Form.Item name='merchantCheckoutType' label={translate('Portal checkout type')}>
                                <Select onChange={(value) => setAlterRequest({ ...alterRequest, merchantCheckoutType: value })}>
                                    {Object.keys(MerchantCheckoutType).map((key) => (
                                        <Select.Option key={key} value={key}>{translate(key)}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='orderMinimumAmountLimit'
                                label={
                                    <span>
                                        {translate('Minimum order amount')}
                                        <MinimumAmountLimitPopover />
                                    </span>
                                }
                            >
                                <InputNumber
                                    min={0}
                                    onChange={(value) => setAlterRequest({ ...alterRequest, orderMinimumAmountLimit: value || 0 })}
                                />
                            </Form.Item>
                            <Form.Item hidden name='isEnableInovice' label={translate('Enable merchant invoice module')} valuePropName='checked'>
                                <Checkbox onChange={(e) => setAlterRequest({ ...alterRequest, isEnableInovice: e.target.checked })} />
                            </Form.Item>
                        </Form>
                    </Flex>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 24 }}>
                    <Flex justify='center'>
                        <Button
                            loading={alterIsloading || alterIconIsloading}
                            type="primary"
                            onClick={handleSave}
                        >
                            {translate('Save')}
                        </Button>
                    </Flex>
                </Col>
            </Row>
        </>
    );
}));

export default PortalGlobalSystemSetting;