import { IAlterMerchantThirdPartyLogisticSettingRequest } from "@/interfaces/Requests/Requests";
import { IBackOfficeMerchantThirdPartyLogisticSettingViewModel } from "@/interfaces/Responses/Responses";
import { useAlterMerchantThirdPartyLogisticSettingApi, useGetBackOfficeMerchantThirdPartyLogisticSettingViewModelsApi } from "@/lib/api/thirdPartyLogistics";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Switch, Table, Tag } from "antd";
import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useMutation } from "react-query";
import { ThirdPartyPageContext } from "../../Contexts/ThirdPartyPageContext";
import AddLogistic, { IAddLogisticSettingRef } from "./AddLogistic";
import EditLogistic, { IEditLogisticSettingRef } from "./EditLogistic";

export interface ILogisticSettingListProps { }
export interface ILogisticSettingListRef {
    onRefresh: () => void;
}

const LogisticList = React.forwardRef((props: ILogisticSettingListProps, ref: React.ForwardedRef<ILogisticSettingListRef | undefined>) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const { abortController } = useContext(ThirdPartyPageContext);
    const addRef = useRef<IAddLogisticSettingRef>();
    const editRef = useRef<IEditLogisticSettingRef>();
    const [merchantThirdPartySettings, setMerchantThirdPartySettings] = useState<IBackOfficeMerchantThirdPartyLogisticSettingViewModel[]>([]);

    const { data, mutate, isLoading } = useMutation(async () => await useGetBackOfficeMerchantThirdPartyLogisticSettingViewModelsApi(abortController.current.signal), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setMerchantThirdPartySettings(response.result || []);
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const { mutate: alterMutate, isLoading: alterMutateIsLoading } = useMutation(async (request: IAlterMerchantThirdPartyLogisticSettingRequest) => await useAlterMerchantThirdPartyLogisticSettingApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                refresh();
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });

    const [selectedEditSetting, setSelectedEditSetting] = useState<IBackOfficeMerchantThirdPartyLogisticSettingViewModel | undefined>(undefined);
    const handleToggleDisable = (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel, isDisable: boolean) => {
        alterMutate({
            settingId: record.thirdPartySettingId,
            setting: record.merchantSettings,
            isDisabled: isDisable
        });
    };
    const [openAddSetting, setOpenAddSetting] = useState(false);

    const columns = [
        {
            title: translate('Provider Name'),
            dataIndex: 'providerName',
            sorter: (a: IBackOfficeMerchantThirdPartyLogisticSettingViewModel, b: IBackOfficeMerchantThirdPartyLogisticSettingViewModel) => a.providerName.localeCompare(b.providerName),
            key: 'providerName',
            render: (text: string, record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel) => (
                <Space >
                    {!record.isReleaseNow && (
                        <Tag color="red" icon={<ExclamationCircleOutlined />}>
                            {translate('Under Maintenance')}
                        </Tag>
                    )}
                    {text}
                    {
                        record.isSupportedForwardFlow && (
                            <Tag color="blue">
                                {translate('Forward', 'Logistic')}
                            </Tag>
                        )
                    }
                    {
                        record.isSupportedReverseFlow && (
                            <Tag color="orange">
                                {translate('Reverse', 'Logistic')}
                            </Tag>
                        )
                    }
                </Space>
            ),
        },
        {
            title: translate('Enable'),
            key: 'isDisable',
            render: (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel) => (
                <Switch
                    loading={isLoading || alterMutateIsLoading}
                    checked={!record.isDisable}
                    onChange={(checked) => handleToggleDisable(record, !checked)}
                />
            ),
        },
        {
            title: translate('Operation'),
            key: 'action',
            render: (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel) => (
                <Button onClick={() => handleEdit(record)}>
                    {translate('Edit')}
                </Button>
            ),
        },
    ];

    const handleEdit = (record: IBackOfficeMerchantThirdPartyLogisticSettingViewModel) => {
        setSelectedEditSetting(record);
    };

    const refresh = () => {
        mutate();
    };

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            console.log('refresh logistic');
            refresh();
        }
    }));

    useEffect(() => {
        refresh();
    }, []);

    return (
        <>
            <Table
                loading={isLoading}
                columns={columns}
                pagination={{ position: ['bottomCenter'] }}
                dataSource={merchantThirdPartySettings}
                footer={() => (
                    <Button
                        type="dashed"
                        onClick={() => setOpenAddSetting(true)}
                        block
                        icon={<PlusOutlined />}
                    >
                        {translate('Add new setting')}
                    </Button>
                )}
                rowKey={record => record.id.toString()}
                bordered
            />
            <Modal
                open={selectedEditSetting !== undefined}
                afterOpenChange={(open) => {
                    if (!open)
                        editRef.current?.onClose();
                    else
                        editRef.current?.onOpen(selectedEditSetting!);
                }}
                onCancel={() => setSelectedEditSetting(undefined)}
                footer={null}
            >
                <EditLogistic
                    ref={editRef}
                    onRefresh={() => refresh()}
                    onClose={() => setSelectedEditSetting(undefined)}
                />
            </Modal>
            <Modal
                open={openAddSetting}
                afterOpenChange={(open) => {
                    if (open) addRef.current?.refresh();
                }}
                onCancel={() => setOpenAddSetting(false)}
                footer={null}
            >
                <AddLogistic
                    ref={addRef}
                    ownRecords={merchantThirdPartySettings}
                    onRefresh={() => refresh()}
                    onClose={() => setOpenAddSetting(false)}
                />
            </Modal>
        </>
    );
});

export default LogisticList;
