import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { IItemViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Form, GetProp, Input, Tag, Tooltip, Upload, UploadFile, UploadProps } from "antd";
import Title from "antd/es/typography/Title";
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";

export interface BasicInformationProps {
    previewOnly?: boolean;
    onIsAllValid: (isAllValid: boolean) => void;
}

export interface IBasicInformationRef {
    validate: () => void;
}

const BasicInformation = React.forwardRef((props: BasicInformationProps, ref: React.ForwardedRef<IBasicInformationRef | undefined>) => {
    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
    const [form] = Form.useForm();
    const {
        itemViewModel,
        setItemViewModel,
        itemConverImageFiles,
        setItemConverImageFiles,
    } = useContext(ItemContext);
    const imageCountLimit = 6;
    const { generateHelper, merchantId } = useContext(GlobalContext);
    const { messageApi } = useContext(GlobalContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const [tagInput, setTagInput] = useState<string>('');

    const getBase64 = (file: FileType): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }
    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        if (newFileList.length <= imageCountLimit) {
            setItemConverImageFiles(newFileList);
        }
    };

    const handleBeforeUpload: UploadProps['beforeUpload'] = (file, fileList) => {
        if (itemConverImageFiles.length >= imageCountLimit) {
            return Upload.LIST_IGNORE;
        }
        const remainingSlots = imageCountLimit - itemConverImageFiles.length;
        if (fileList.length > remainingSlots) {
            setItemConverImageFiles([...itemConverImageFiles, ...fileList.slice(0, remainingSlots)]);
            return Upload.LIST_IGNORE;
        }
        return false;
    };

    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const handleTagDelete = (removedTag: string) => {
        setItemViewModel({
            ...itemViewModel,
            itemTags: itemViewModel.itemTags.filter(tag => tag.name !== removedTag),
        });
    };

    const handleTagAdd = () => {
        if (tagInput) {
            setTagInput('');
            if (itemViewModel.itemTags.find(tag => tag.name === tagInput)) {
                return;
            }
            setItemViewModel({
                ...itemViewModel,
                itemTags: [...itemViewModel.itemTags, {
                    merchantId: merchantId!,
                    id: generateHelper.getSnowflakeIdBigInt(),
                    name: tagInput,
                    isDeleted: false,
                }],
            });
        }
    };

    useEffect(() => {
        if (itemViewModel === undefined)
            return;
        // 初始化表单字段
        form.setFieldsValue({
            itemName: itemViewModel.name,
            itemTitle: itemViewModel.title,
            itemBriefly: itemViewModel.briefly,
        });
    }, [itemViewModel]);

    useImperativeHandle(ref, () => ({
        // Method to validate the form
        validate: async () => {
            try {
                const result = await form.validateFields();
                var isOk = result.errorFields === undefined;
                props.onIsAllValid(isOk);
                // If there are no error fields, return true
                props.onIsAllValid(true);
                return isOk;
            } catch (errorInfo: any) {
                // If there are error fields, return false
                props.onIsAllValid(errorInfo.errorFields.length === 0);
                return errorInfo.errorFields.length === 0;
            }
        },
    }));

    async function onValueChange(changeValues: any, value: any) {
        var final: IItemViewModel = {
            ...itemViewModel,
            id: itemViewModel.id || generateHelper.getSnowflakeIdBigInt(), // new Item will have a new id
            itemConverImagePaths: [...itemViewModel!.itemConverImagePaths],
            name: value.itemName,
            title: value.itemTitle,
            briefly: value.itemBriefly,
            itemTags: itemViewModel.itemTags || [],
            isDelete: false,
            description: itemViewModel.description || '',
            createdDate: itemViewModel.createdDate || '',
            alterDate: itemViewModel.alterDate || '',
            isOnShelf: itemViewModel.isOnShelf || false,
        };
        setItemViewModel(final);

        try {
            var result = await form.validateFields();
            //if has result.errorFields property then it is not ok
            var isOk = result.errorFields === undefined;
            props.onIsAllValid(isOk);
        } catch (errorInfo: any) {
            props.onIsAllValid(errorInfo.errorFields.length === 0);
        }
    }

    return (
        <>
            <Form style={{ width: '80%' }} form={form} onValuesChange={onValueChange}>
                <Flex justify="center">
                    <Title level={3}>
                        {translate('Basic Information')}
                    </Title>
                </Flex>
                <Flex style={{ margin: '10px' }}>
                    <Tooltip title={
                        i18nLanguage === 'zh_TW'
                            ? '圖片最佳比例為1:1，解析度800*800像素（或以上）'
                            : 'The default ratio is 1:1 and the resolution of 800*800px (or above) is optimal for uploaded images.'
                    }>
                        <Upload
                            listType="picture-card"
                            fileList={itemConverImageFiles}
                            onPreview={handlePreview}
                            onChange={handleChange}
                            beforeUpload={handleBeforeUpload}
                            multiple
                            disabled={props.previewOnly}
                        >
                            {itemConverImageFiles.length >= imageCountLimit ? null : uploadButton}
                        </Upload>
                    </Tooltip>
                </Flex>
                {/* Add item tags input */}
                <Flex style={{ width: '100%', marginTop: 10, marginBottom: 10 }} justify={"center"} align={"middle"} wrap="wrap">
                    {itemViewModel.itemTags.map((tag, index) => (
                        <Tag
                            key={tag.name}
                            closable={!props.previewOnly}
                            onClose={() => {
                                handleTagDelete(tag.name)
                            }}>
                            {tag.name}
                        </Tag>
                    ))}
                </Flex>
                <Form.Item
                    hidden={props.previewOnly}
                    label={translate('Item tags')}>
                    <Input
                        style={{ width: 100 }}
                        placeholder={translate('Tags')}
                        value={tagInput}
                        onChange={(e) => setTagInput(e.target.value)}
                        onPressEnter={handleTagAdd}
                    />
                    <Button style={{ marginLeft: '10px' }} onClick={handleTagAdd} disabled={!tagInput} type="primary">{translate('Add')}</Button>
                </Form.Item>

                {/* ItemName */}
                <Form.Item
                    name="itemName"
                    rules={[{ required: true, message: translate('is required') }]}
                    label={translate('Item name')}>
                    <Input
                        disabled={props.previewOnly} />
                </Form.Item>
                {/* ItemTitle */}
                <Form.Item
                    name="itemTitle"
                    rules={[{ required: true, message: translate('is required') }]}
                    label={translate('Item title')}>
                    <Input
                        disabled={props.previewOnly} />
                </Form.Item>
                {/* ItemBriefly */}
                <Form.Item
                    name="itemBriefly"
                    rules={[{ required: true, message: translate('is required') }]}
                    label={translate('Item briefly')}>
                    <Input
                        disabled={props.previewOnly} />
                </Form.Item>
            </Form>
        </>
    )
});

export default BasicInformation;
