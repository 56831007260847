import { DeviceType } from '@/Templates/enums/templateEnums';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Empty, Image, Popover } from "antd";
import React, { useContext, useEffect } from "react";
import ipRangeImage from '../../../../../assets/ECPay/ECPayIpRange.png';
import merchantSettingImage from '../../../../../assets/ECPay/ECpayBackendSetting.png';

interface InfoationPopoverProps {
    providerName: string;
}

const InfoationPopover: React.FC<InfoationPopoverProps> = ({ providerName }) => {
    const { deviceType } = useContext(GlobalContext);
    const { i18nLanguage } = useContext(TranslationContext);
    const [content, setContent] = React.useState<React.ReactNode>(<></>);
    const maxWidth = deviceType === DeviceType.Mobile ? '100vw' : '50vw';

    const content_TW: Record<string, React.ReactNode> = {
        "綠界": (
            <div>
                <ol>
                    <li>先向綠界申請帳號，並開啟相關功能。</li>
                    <li>
                        至綠界後台查看系統介接設定，獲取相對應模組設定資訊：
                        <ul>
                            <li>商店代號</li>
                            <li>HashKey</li>
                            <li>HashIV</li>
                        </ul>
                        <Image src={merchantSettingImage} alt="圖一" style={{ width: '20%', marginTop: '10px' }} />
                    </li>
                    <li>根據所獲取的資訊填寫至我方系統後台。</li>
                    <li>
                        網路 IP 範圍設定（全開啟 0.0.0.0 ~ 255.255.255.255）。
                        <Image src={ipRangeImage} alt="圖二" style={{ width: '20%', marginTop: '10px' }} />
                    </li>
                </ol>
            </div>
        ),
        "其他": <Empty />
    };

    const content_EN: Record<string, React.ReactNode> = {
        "綠界": (
            <div>
                <ol>
                    <li>First, apply for an account with ECPay and enable the relevant functions.</li>
                    <li>
                        Go to the ECPay backend and view the system integration settings to obtain the corresponding module setting information:
                        <ul>
                            <li>Store ID</li>
                            <li>HashKey</li>
                            <li>HashIV</li>
                        </ul>
                        <Image src={merchantSettingImage} alt="Figure 1" style={{ width: '20%', marginTop: '10px' }} />
                    </li>
                    <li>Fill in the obtained information in our system backend.</li>
                    <li>
                        Set the network IP range (fully open 0.0.0.0 ~ 255.255.255.255).
                        <Image src={ipRangeImage} alt="Figure 2" style={{ width: '20%', marginTop: '10px' }} />
                    </li>
                </ol>
            </div>
        ),
        "其他": <Empty />
    };

    const getContent = (): React.ReactNode => {
        const key = Object.keys(i18nLanguage === 'zh_TW' ? content_TW : content_EN).find(
            k => providerName.startsWith(k)
        );

        if (!key) {
            return i18nLanguage === 'zh_TW' ? content_TW['其他'] : content_EN['其他'];
        }

        return i18nLanguage === 'zh_TW' ? content_TW[key] : content_EN[key];
    };

    useEffect(() => {
        setContent(getContent());
    }, [i18nLanguage, providerName]);

    return (
        <Popover
            content={content}
            trigger="click"
            overlayStyle={{ maxWidth }} // Conditionally set maximum width based on device type
        >
            <QuestionCircleTwoTone />
        </Popover>
    );
};

export default InfoationPopover;
