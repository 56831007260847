import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Tabs } from "antd";
import React, { useContext } from "react";
import MerchantAssistantManage from "./Components/MerchantAssistantManage";
import MyAssistantInvitesList from "./Components/MyAssistantInvitesList";

const { TabPane } = Tabs;

const AssistantManagePage: React.FC = () => {
    const { merchantId } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);

    return (
        <div>
            <Tabs defaultActiveKey="myInvite">
                <TabPane tab={translate('My invitations')} key="myInvite">
                    <MyAssistantInvitesList />
                </TabPane>
                <TabPane tab={`${translate('Assistant')} ${translate('List')}`} key="assistantList">
                    <MerchantAssistantManage></MerchantAssistantManage>
                </TabPane>
            </Tabs>
        </div>
    );
}

export default AssistantManagePage;