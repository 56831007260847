import { DeviceType } from "@/Templates/enums/templateEnums";
import { ThirdPartyPlatformServiceProvider } from "@/enums/ThirdPartyPlatformService";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Empty, Flex, Image, Popover } from "antd";
import Title from "antd/es/typography/Title";
import React, { useContext, useEffect, useState } from "react";
import GoogleGAImage1 from '../../../../../assets/GoogleGA/GA-EN-Step1.png';
import GoogleGAImage2 from '../../../../../assets/GoogleGA/GA-EN-Step2.png';
import LineBotBasicID from '../../../../../assets/Line/LineBotBasicID.png';
import LineChannelAccessToken from '../../../../../assets/Line/LineChannelAccessToken.png';
import LineChannelID from '../../../../../assets/Line/LineChannelID.png';
import LineDev01 from '../../../../../assets/Line/LineDev_01.png';

export interface IProviderGuidePopoversProps {
    provider: ThirdPartyPlatformServiceProvider;
    module: string;
}

const ProviderGuidePopovers: React.FC<IProviderGuidePopoversProps> = ({ provider, module }) => {
    const { deviceType } = useContext(GlobalContext);
    const { i18nLanguage, translate } = useContext(TranslationContext);
    const [content, setContent] = useState<React.ReactNode>(<></>);

    const maxWidth = deviceType === DeviceType.Mobile ? '100vw' : '50vw';
    const maxHeight = '80vh';

    const getProviderContent = (provider: ThirdPartyPlatformServiceProvider, language: string): React.ReactNode => {
        const contentMap: Record<ThirdPartyPlatformServiceProvider, Record<string, { zh_TW: React.ReactNode, en_US: React.ReactNode }>> = {
            [ThirdPartyPlatformServiceProvider.Line]: {
                "MessageGroup": {
                    zh_TW: (
                        <div>
                            <ol>
                                <li>此參數說明 Line 群組設定：</li>
                                <li>影響前端是否顯示 Line 迷你圖示，點擊後可加入 Line 群組。</li>
                                <li>設定步驟：
                                    <ol>
                                        <li>
                                            用戶請先至 <a href="https://developers.line.biz/console/" target="_blank" rel="noopener noreferrer">line 開發者後台</a> 申請群組。
                                            <Image src={LineDev01} alt="Line Developer Console" />
                                        </li>
                                        <li>
                                            接著，依序填寫所需資料：
                                            <ul>
                                                <li>
                                                    ChannelID
                                                    <Image src={LineChannelID} alt="Line Channel ID" />
                                                </li>
                                                <li>
                                                    LineChannelAccessToken
                                                    <Image src={LineChannelAccessToken} alt="Line Channel Access Token" />
                                                </li>
                                                <li>
                                                    LineBotBasicID
                                                    <Image src={LineBotBasicID} alt="Line Bot Basic ID" />
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    ),
                    en_US: (
                        <div>
                            <ol>
                                <li>Line group settings description:</li>
                                <li>Affects whether the frontend displays a Line mini icon, which when clicked, allows joining the Line group.</li>
                                <li>Setup steps:
                                    <ol>
                                        <li>
                                            First, go to the <a href="https://developers.line.biz/console/" target="_blank" rel="noopener noreferrer">Line Developer Console</a> to create a group.
                                            <Image src={LineDev01} alt="Line Developer Console" />
                                        </li>
                                        <li>
                                            Then, fill in the required information:
                                            <ul>
                                                <li>
                                                    ChannelID
                                                    <Image src={LineChannelID} alt="Line Channel ID" />
                                                </li>
                                                <li>
                                                    LineChannelAccessToken
                                                    <Image src={LineChannelAccessToken} alt="Line Channel Access Token" />
                                                </li>
                                                <li>
                                                    LineBotBasicID
                                                    <Image src={LineBotBasicID} alt="Line Bot Basic ID" />
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    )
                }
            },
            [ThirdPartyPlatformServiceProvider.Facebook]: {
                "MessageGroup": {
                    zh_TW: (
                        <div>
                            <ol>
                                <li>此參數說明 Facebook 群組設定：</li>
                                <li>影響前端是否顯示 Facebook 迷你圖示，點擊後可加入 Facebook 群組。</li>
                            </ol>
                        </div>
                    ),
                    en_US: (
                        <div>
                            <ol>
                                <li>Facebook group settings description:</li>
                                <li>Affects whether the frontend displays a Facebook mini icon, which when clicked, allows joining the Facebook group.</li>
                            </ol>
                        </div>
                    )
                }
            },
            [ThirdPartyPlatformServiceProvider.Google]: {
                "GA": {
                    zh_TW: (
                        <div>
                            <p>
                                我方平台協助串流資訊至Google GA服務，根據
                                <a
                                    href="https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=zh-tw&client_type=gtag"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    GA官方文件
                                </a>
                                ，我方進行整合。
                            </p>
                            <p>以下為我方會提供之資訊：</p>
                            <ul>
                                <li>page_view</li>
                                <li>sign_up (only count platform regist)</li>
                                <li>login</li>
                                <li>add_to_cart</li>
                                <li>remove_from_cart</li>
                                <li>add_payment_info</li>
                                <li>purchase</li>
                            </ul>
                            <div>
                                <ol>
                                    <li>請先至 Google GA 申請帳號</li>
                                    <Image src={GoogleGAImage1} alt="Step 1" style={{ width: '20%' }} />
                                    <li>申請資料串流根據圖一</li>
                                    <Image src={GoogleGAImage2} alt="Step 2" style={{ width: '20%' }} />
                                    <li>
                                        填寫正確 Stream Domain 取得 MEASUREMENT ID
                                        <br />
                                        Domain 範例為 前台鏈結/商戶號/
                                        <br />
                                        Example: https://portal.junxiaotech.com/1541815603606036480/
                                        <br />
                                        若有客制綁定 Domain 則為自行申請綁定之 Domain
                                    </li>
                                </ol>
                            </div>
                        </div>
                    ),
                    en_US: (
                        <div>
                            <p>
                                Our platform assists in streaming information to Google GA services. According to the
                                <a
                                    href="https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    GA official documentation
                                </a>
                                , we integrate as follows.
                            </p>
                            <p>We provide the following information:</p>
                            <ul>
                                <li>page_view</li>
                                <li>login</li>
                                <li>add_to_cart</li>
                                <li>add_payment_info</li>
                                <li>purchase</li>
                            </ul>
                            <div>
                                <ol>
                                    <li>First, apply for a Google GA account</li>
                                    <Image src={GoogleGAImage1} alt="Step 1" style={{ width: '20%' }} />
                                    <li>Apply for data stream according to Step 1</li>
                                    <Image src={GoogleGAImage2} alt="Step 2" style={{ width: '20%' }} />
                                    <li>
                                        Fill in the correct Stream Domain to obtain the MEASUREMENT ID
                                        <br />
                                        Domain example: front-end link/merchant number/
                                        <br />
                                        Example: https://portal.junxiaotech.com/1541815603606036480/
                                        <br />
                                        If you have a custom bound domain, use the domain you applied for binding
                                    </li>
                                </ol>
                            </div>
                        </div>
                    )
                }
            }
        };

        return contentMap[provider]?.[module]?.[language as 'zh_TW' | 'en_US'] || (
            <Empty description={translate('No content available')} />
        );
    };

    useEffect(() => {
        setContent(getProviderContent(provider, i18nLanguage));
    }, [provider, i18nLanguage, translate]);

    return (
        <Popover
            trigger="hover"
            arrow={false}
            content={
                <div style={{
                    maxHeight,
                    overflowY: 'auto',
                    padding: '16px'
                }}>
                    {content}
                </div>
            }
            overlayStyle={{
                maxWidth,
            }}
            overlayInnerStyle={{
                maxHeight,
                overflow: 'hidden'
            }}
        >
            <Flex justify="left" align="center">
                <QuestionCircleTwoTone />
                <Title level={5} style={{ marginBottom: 0 }}> {translate('Tutorial')}</Title>
            </Flex>
        </Popover>
    );
};

export default ProviderGuidePopovers;

