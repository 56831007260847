import { DeviceType } from "@/Templates/enums/templateEnums";
import { IChildrenProviderProps } from "@/interfaces/GlobalContextType";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import QuestionCircleTwoTone from '@ant-design/icons/lib/icons/QuestionCircleTwoTone';
import { Flex, Image, Popover } from "antd";
import Title from "antd/es/typography/Title";
import React, { useContext, useEffect } from "react";
import DNS_AddCNameRecord from '../../../assets/SSL/DNS_AddCNameRecord.png';
import DNS_BackOfficeImage from '../../../assets/SSL/DNS_BackOffice.png';
import DNS_ManageRecordImage from '../../../assets/SSL/DNS_ManageRecord.png';

const SSLSettingGuidePopover: React.FC<IChildrenProviderProps> = ({ children }) => {
    const { deviceType } = useContext(GlobalContext);
    const { translate, i18nLanguage } = useContext(TranslationContext);
    const [content, setContent] = React.useState<React.ReactNode>(<></>);
    const maxWidth = deviceType === DeviceType.Mobile ? '100vw' : '50vw';

    const contentZhTw = (
        <div>
            <ol>
                <li>
                    商戶綁定需要 SSL 的網址，例如：https://www.testDomain.com
                    請先備妥：
                    <ul>
                        <li>域名</li>
                        <li>自行申請之 SSL 憑證 (.crt) 及金鑰 (.key)</li>
                    </ul>
                </li>
                <li>
                    至購買方網站（以 GoDaddy 為例）登入後台，找尋到 DNS 管理。
                </li>
                <li>
                    找到 DNS 管理中，可以設定紀錄的位置。
                    <Image src={DNS_ManageRecordImage} alt="圖一" style={{ width: '20%', marginTop: '10px' }} />
                </li>
                <li>
                    新增紀錄，選擇 CNAME 紀錄（以 www.貴司申請的網域 為範例），指定內容至我方提供的網址：
                    <p style={{ color: 'red', marginTop: '10px' }}>proxy.junxiaotech.com.</p>
                    <p style={{ color: 'red' }}>注意：最後一定要有一個點。</p>
                    <Image src={DNS_AddCNameRecord} alt="圖二" style={{ width: '20%', marginTop: '10px' }} />
                </li>
                <li>
                    至我方平台後台，設定 SSL，填寫所綁定之網址，上傳 SSL 憑證及金鑰。
                    <Image src={DNS_BackOfficeImage} alt="圖三" style={{ width: '20%', marginTop: '10px' }} />
                </li>
            </ol>
        </div>
    );

    const contentEn = (
        <div>
            <ol>
                <li>
                    The merchant needs to bind the URL requiring SSL, e.g., https://www.testDomain.com. Please prepare:
                    <ul>
                        <li>Domain name</li>
                        <li>Self-applied SSL certificate (.crt) and key (.key)</li>
                    </ul>
                </li>
                <li>
                    Log in to the backend of the purchase site (e.g., GoDaddy) and find the DNS management.
                </li>
                <li>
                    Find the location where records can be set in DNS management.
                    <Image src={DNS_ManageRecordImage} alt="Figure 1" style={{ width: '20%', marginTop: '10px' }} />
                </li>
                <li>
                    Add a record, select CNAME record (e.g., www.yourcompanydomain), and point it to the URL provided by us:
                    <p style={{ color: 'red', marginTop: '10px' }}>proxy.junxiaotech.com.</p>
                    <p style={{ color: 'red' }}>Note: There must be a dot at the end.</p>
                    <Image src={DNS_AddCNameRecord} alt="Figure 2" style={{ width: '20%', marginTop: '10px' }} />
                </li>
                <li>
                    Go to our platform backend, set SSL, fill in the bound URL, and upload the SSL certificate and key.
                    <Image src={DNS_BackOfficeImage} alt="Figure 3" style={{ width: '20%', marginTop: '10px' }} />
                </li>
            </ol>
        </div>
    );

    const getContent = (): React.ReactNode => {
        switch (i18nLanguage) {
            case 'zh_TW':
                return contentZhTw;
            case 'en_US':
                return contentEn;
            default:
                return contentZhTw;
        }
    };

    useEffect(() => {
        setContent(getContent());
    }, [i18nLanguage]);

    return (
        <Popover
            content={content}
            trigger="hover"
            title="SSL Setting Guide (GoDaddy Example)"
            overlayStyle={{ maxWidth }} // Conditionally set maximum width based on device type
        >
            <Flex justify="center">
                <QuestionCircleTwoTone color="red" />
                <Title level={5} style={{ marginBottom: 0 }}> {translate('Tutorial')}</Title>
            </Flex>
        </Popover>
    );
};

export default SSLSettingGuidePopover;
