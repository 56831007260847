import React from 'react';

export interface IThirdPartyPageContext {
    abortController: React.MutableRefObject<AbortController>;
}

export const ThirdPartyPageContext = React.createContext<IThirdPartyPageContext>({} as IThirdPartyPageContext);

export const ThirdPartyPageContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const abortController = React.useRef(new AbortController());

    const contextValue: IThirdPartyPageContext = {
        abortController,
    };

    return (
        <ThirdPartyPageContext.Provider value={contextValue}>
            {children}
        </ThirdPartyPageContext.Provider>
    );
};