import { IOperationResult, IOperationResultT } from "@/interfaces/Responses/Responses";
import useAPI from "../customHooks/useAPI";

/// <summary>
/// 驗證 Token 是否有效
/// </summary>
export const useValidateTokenApi = () => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Token/ValidateToken`);

export const useGetRolesApi = () => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/Token/GetRoles`);

export const useGetMicroShopRolesApi = () => useAPI<IOperationResultT<string[]>>(`${process.env.BASE_API_URL}/api/Token/GetMicroShopRoles`);

export const useRefreshTokenApi = () => useAPI<IOperationResultT<string>>(`${process.env.BASE_API_URL}/api/Token/RefreshToken`, {
    method: 'POST'
});