import { FrontEndDisplayType } from "@/enums/Enums";
import {
    LogisticOrderStatus,
    LogisticOrderType
} from "@/enums/ThirdPartyLogistic";
import { IGetThirdPartyOrdersByPlatformOrderRequest, IQueryThirdPartyOrderStatusRequest } from "@/interfaces/Requests/Requests";
import { ICreateThirdPartyLogisticOrderRequest } from "@/interfaces/Requests/ThirdPartyLogistic";
import { IOrder } from "@/interfaces/Responses/Responses";
import { IThirdPartyLogisticOrderViewModel } from "@/interfaces/Responses/ThirdPartyLogistic";
import {
    useCreateStickerInfoApi,
    useCreateThirdPartyLogisticOrderApi,
    useGetMemberLogisticOptionApi,
    useGetThirdPartyLogisticSettingApi,
    useGetThirdPartyOrdersByPlatformOrderApi,
    useQueryLogisticOrderStatusApi
} from "@/lib/api/thirdPartyLogistics";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { UrlHelper } from "@/lib/helpers/UrlHelper";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { Button, Card, Descriptions, Divider, Flex, Modal, Tag } from "antd";
import JSONBig from 'json-bigint';
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import LogisticSelector, { ILogisticSelectorRef } from "./LogisticSelector";

export interface ILogisticOrderProps {
    order: IOrder;
}

export interface ILogisticOrderRef {
    onOpen: () => void;
}

const LogisticOrders = React.forwardRef((props: ILogisticOrderProps, ref: React.ForwardedRef<ILogisticOrderRef | undefined>) => {
    const urlHelper = new UrlHelper();
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();

    const [logisticOrders, setLogisticOrders] = useState<IThirdPartyLogisticOrderViewModel[]>([]);
    const [addLogisticOrderVisible, setAddLogisticOrderVisible] = useState<boolean>(false);

    const logisticSelectorRef = useRef<ILogisticSelectorRef>();

    // Get member logistic option
    const { data: memberLogisticOption, isLoading: isLoadingMemberLogisticOption } = useQuery(
        ['memberLogisticOption', props.order.memberLogisticOptionId],
        () => useGetMemberLogisticOptionApi(props.order.memberLogisticOptionId),
        {
            enabled: !!props.order.memberLogisticOptionId,
        }
    );

    // Get logistic setting
    const { data: logisticSetting, isLoading: isLoadingLogisticSetting } = useQuery(
        ['logisticSetting', memberLogisticOption?.result?.thirdPartyLogisticId],
        () => useGetThirdPartyLogisticSettingApi(memberLogisticOption!.result!.thirdPartyLogisticId),
        {
            enabled: !!memberLogisticOption?.result?.thirdPartyLogisticId,
        }
    );

    // Get logistic orders
    const { mutate: mutateLogisticOrders, isLoading: isLoadingLogisticOrders } = useMutation(
        async (request: IGetThirdPartyOrdersByPlatformOrderRequest) => await useGetThirdPartyOrdersByPlatformOrderApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess && response.result) {
                    setLogisticOrders(response.result);
                }
            }
        }
    );

    // Query logistic order status
    const { mutate: queryLogisticOrderStatusMutate, isLoading: queryLogisticOrderStatusLoading } = useMutation(
        async (request: IQueryThirdPartyOrderStatusRequest) => await useQueryLogisticOrderStatusApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    const data = JSONBig.parse(response.result?.data || '{}');
                    switch (response.result?.type) {
                        case FrontEndDisplayType.None:
                            mutateLogisticOrders({ orderId: props.order.id });
                            break;
                        case FrontEndDisplayType.PageFormPost:
                            urlHelper.pageFormPost(response.result?.url, data);
                            break;
                        case FrontEndDisplayType.PageRedirectURL:
                            urlHelper.openRedirect(response.result?.url, data);
                            break;
                        case FrontEndDisplayType.OpenNewPage:
                            urlHelper.openInPopup(response.result?.url, data);
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    );

    const createStickerInfoApi = useMutation(async (thirdPartyOrderId: BigInt) => await useCreateStickerInfoApi(thirdPartyOrderId), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                const data = JSONBig.parse(response.result?.data || '{}');
                switch (response.result?.type) {
                    case FrontEndDisplayType.PageFormPost:
                        urlHelper.pageFormPost(response.result?.url, data, '_blank');
                        break;
                    case FrontEndDisplayType.PageRedirectURL:
                        urlHelper.openRedirect(response.result?.url, data);
                        break;
                    case FrontEndDisplayType.OpenNewPage:
                        urlHelper.openInPopup(response.result?.url, data);
                        break;
                    default:
                        break;
                }
            } else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    })

    // Create logistic order
    const { mutate: createLogisticOrderMutate, isLoading: createLogisticOrderLoading } = useMutation(
        async (request: ICreateThirdPartyLogisticOrderRequest) => await useCreateThirdPartyLogisticOrderApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate("Logistic order created successfully"));
                    setAddLogisticOrderVisible(false);
                    mutateLogisticOrders({ orderId: props.order.id });
                } else {
                    messageApi.error(translate(response.message || "Failed to create logistic order"));
                }
            }
        }
    );

    useEffect(() => {
        if (merchantId) {
            mutateLogisticOrders({ orderId: props.order.id });
        }
    }, [merchantId]);

    const handleCreateLogisticOrder = () => {
        setAddLogisticOrderVisible(true);
    };

    const handleQueryLogisticOrderStatus = (thirdPartyLogisticOrderId: BigInt) => {
        queryLogisticOrderStatusMutate({ thirdPartyOrderId: thirdPartyLogisticOrderId });
    }

    const handleGetSticker = (thirdPartyLogisticOrderId: BigInt) => {
        createStickerInfoApi.mutate(thirdPartyLogisticOrderId);
    }

    const getLogisticOrderStatusColor = (status: LogisticOrderStatus) => {
        switch (status) {
            case LogisticOrderStatus.Created:
            case LogisticOrderStatus.DuringShipping:
            case LogisticOrderStatus.ArrivalWaitForPickup:
                return 'blue';
            case LogisticOrderStatus.Done:
                return 'green';
            case LogisticOrderStatus.ExpiredNotPickup:
            case LogisticOrderStatus.Cancel:
                return 'red';
            case LogisticOrderStatus.Unknow:
                return 'gray'
            default:
                return 'default';
        }
    };

    const renderMemberLogisticOption = () => {
        if (isLoadingLogisticSetting || isLoadingMemberLogisticOption) return (
            <LoadingComponent></LoadingComponent>
        );

        if (!memberLogisticOption?.result || !logisticSetting?.result) return (
            null
        );

        const canCreateLogisticOrder = props.order.isCollection || (props.order.isPaid && logisticOrders.length === 0 || logisticOrders.some(order => !order.isSuccessCreateFromProvider));

        return (
            <Card title={translate('Buyer Logistic Option')} style={{ marginBottom: 16 }}>
                <Descriptions column={1}>
                    <Descriptions.Item label={translate('Provider Name')}>{logisticSetting.result.providerName}</Descriptions.Item>
                    <Descriptions.Item label={translate('Type')}>{translate(memberLogisticOption.result.logisticsMainType,)}</Descriptions.Item>
                    <Descriptions.Item label={translate('LogisticsSubType')}>{translate(`${logisticSetting.result.providerName}.${memberLogisticOption.result.values['LogisticsSubType']}`, 'LogisticsSubType')}</Descriptions.Item>
                    <Descriptions.Item label={translate('IsCollection')}>
                        <Tag color={props.order.isCollection ? 'green' : 'red'}>
                            {translate(`${props.order.isCollection ? 'Yes' : 'No'}`)}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label={translate('Store')}>{memberLogisticOption.result.displayName}</Descriptions.Item>
                    <Descriptions.Item label={translate('Address')}>{memberLogisticOption.result.displayAddress}</Descriptions.Item>
                </Descriptions>
                {canCreateLogisticOrder && (
                    <>
                        <Divider>出貨</Divider>
                        <Flex justify="center" gap="large">
                            <Button
                                onClick={handleCreateLogisticOrder}
                            >
                                {translate('用戶指定廠商')}
                            </Button>
                            <Button
                                disabled={createLogisticOrderLoading}
                                onClick={() => handleCreateLogisticOrder()}
                            >
                                {translate('其他物流廠商')}
                            </Button>
                        </Flex>
                    </>
                )}
            </Card>
        );
    };

    const renderLogisticOrder = (logisticOrder: IThirdPartyLogisticOrderViewModel) => {
        return (
            <Card title={`${translate('Logistic')} ${translate('Order')}`} key={logisticOrder.id.toString()}>
                <Descriptions column={1}>
                    <Descriptions.Item label={translate('Order ID')}>{logisticOrder.id.toString()}</Descriptions.Item>
                    <Descriptions.Item label={translate('Provider Name')}>{logisticOrder.providerName}</Descriptions.Item>
                    {logisticOrder.providerOrderId && (
                        <Descriptions.Item label="Provider Order ID">{logisticOrder.providerOrderId}</Descriptions.Item>
                    )}
                    <Descriptions.Item label={translate('Type')}>{translate(`LogisticOrderType.${logisticOrder.type}`, 'Logistic')}</Descriptions.Item>
                    <Descriptions.Item label={translate('Is Success Create From Provider')}>
                        <span style={{ color: logisticOrder.isSuccessCreateFromProvider ? 'inherit' : 'red' }}>
                            {translate(logisticOrder.isSuccessCreateFromProvider ? 'Yes' : 'No')}
                        </span>
                    </Descriptions.Item>
                    <Descriptions.Item label={translate('Status')}>
                        <Tag color={getLogisticOrderStatusColor(logisticOrder.status)}>
                            {translate(logisticOrder.status)}
                        </Tag>
                    </Descriptions.Item>
                </Descriptions>
                <Flex justify="center" style={{ marginTop: 16 }} gap="large">
                    {logisticOrder.isSuccessCreateFromProvider && logisticOrder.status !== LogisticOrderStatus.Done && (
                        <>
                            <Button
                                type="primary"
                                loading={queryLogisticOrderStatusLoading}
                                onClick={() => handleQueryLogisticOrderStatus(logisticOrder.id)}
                            >
                                {`${translate('Query')} ${translate('Status')}`}
                            </Button>
                            <Button
                                type="primary"
                                loading={createStickerInfoApi.isLoading}
                                onClick={() => handleGetSticker(logisticOrder.id)}
                            >
                                {`${translate('Get Sticker')}`}
                            </Button>
                        </>
                    )}
                </Flex>
            </Card>
        );
    };

    const renderLogisticOrders = () => {
        const sendOrder = logisticOrders.find(order => order.type === LogisticOrderType.Forward);
        if (sendOrder) {
            return renderLogisticOrder(sendOrder);
        }
        return (
            <></>
        );
    };

    return (
        <>
            {renderMemberLogisticOption()}
            <Divider />
            {renderLogisticOrders()}
            <Modal
                open={addLogisticOrderVisible}
                onCancel={() => setAddLogisticOrderVisible(false)}
                footer={null}
                afterOpenChange={(visible) => {
                    if (visible) logisticSelectorRef.current?.onOpen();
                    else logisticSelectorRef.current?.onClose();
                }}
            >
                <LogisticSelector
                    ref={logisticSelectorRef}
                    order={props.order}
                    limitSettingIds={
                        memberLogisticOption?.result?.thirdPartyLogisticId
                            ? [memberLogisticOption.result.thirdPartyLogisticId]
                            : []
                    }
                    onLogisticOrderCreated={() => {
                        mutateLogisticOrders({ orderId: props.order.id });
                    }}
                />
            </Modal>
        </>
    );
});

export default LogisticOrders;
