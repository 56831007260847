import { IDeleteMerchantSSLRequest } from "@/interfaces/Requests/Requests";
import { ISSLBindingDto } from "@/interfaces/Responses/Responses";
import { useDeleteDeleteMerchantSSLApi, useGetSSLBindingApi, useUpsertMerchantSSLApi } from "@/lib/api/webSiteSettings";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { UrlHelper } from "@/lib/helpers/UrlHelper";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Form, Input, Popconfirm, Upload } from "antd";
import FormItem from "antd/es/form/FormItem";
import axios from 'axios';
import React, { useContext, useEffect, useImperativeHandle, useState } from "react";
import { useMutation } from "react-query";
import { PortalSettingPageContext } from "../Contexts/PortalSettingPageContext";
import SSLSettingGuidePopover from "./SSLSettingGuidePopover";

export interface SSLSettingsProps { }

export interface SSLSettingsRef {
    onRefresh: () => void;
}

const SSLSettings = React.forwardRef((props: SSLSettingsProps, ref: React.ForwardedRef<SSLSettingsRef | undefined>) => {
    const { merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const { abortController } = useContext(PortalSettingPageContext);
    const urlHelper = new UrlHelper();
    const [addform] = Form.useForm();
    const [sslBindingDtos, setSSLBindingDtos] = useState<ISSLBindingDto[]>([]);
    const [hasUploadedCrt, setHasUploadedCrt] = useState(false);
    const [hasUploadedKey, setHasUploadedKey] = useState(false);
    const [domainVlidating, setDomainVlidating] = React.useState<boolean>(false);
    const { mutate: getSSlBindingDtosMutate, isLoading: sslSettingsIsLoading } = useMutation(async () => await useGetSSLBindingApi(abortController.current.signal), {
        onSuccess: (data) => {
            //clear the form
            addform.resetFields();
            if (data.isSuccess && data.result) {
                setSSLBindingDtos(data.result);
            }
        }
    });
    const { mutate: upsertSSLBindingMutate } = useMutation(async (request: FormData) => await useUpsertMerchantSSLApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                getSSlBindingDtosMutate();
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const { mutate: deleteSSLBindingMutate } = useMutation(async (request: IDeleteMerchantSSLRequest) => await useDeleteDeleteMerchantSSLApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                getSSlBindingDtosMutate();
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    useEffect(() => {
        getSSlBindingDtosMutate();
    }, []);

    useImperativeHandle(ref, () => ({
        onRefresh: () => {
            getSSlBindingDtosMutate();
        }
    }));

    const handleSubmit = async (value: any) => {
        await addform.validateFields().then(values => {
            const formData = new FormData();
            // Append the domain field
            formData.append('Domain', values.merchantDomain);

            // Handle SSL Certificate file
            if (values.sslCertificate && values.sslCertificate[0]) {
                formData.append('Certification', values.sslCertificate[0].originFileObj);
            }

            // Handle SSL Private Key file
            if (values.sslPrivateKey && values.sslPrivateKey[0]) {
                formData.append('PrivateKey', values.sslPrivateKey[0].originFileObj);
            }

            // Use the mutate function from react-query to submit the FormData
            upsertSSLBindingMutate(formData);
        }).catch(errorInfo => {
            // Handle form validation errors or other submission issues
            console.log("Validation failed:", errorInfo);
        });
    };

    const downloadFile = async (url, filename) => {
        try {
            const response = await axios.get(url, {
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('JWTToken')}`
                }
            });

            // Create a URL for the blob
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', filename); // Set the file name
            document.body.appendChild(link);
            link.click();

            // Clean up and revoke the URL
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(fileURL);
        } catch (error) {
            console.error('Download error:', error);
            messageApi.error(translate('Operation failed'));
        }
    }

    const handleFileChange = (fileList: any[], type: 'crt' | 'key') => {
        const hasFile = fileList.length > 0;
        if (type === 'crt') {
            setHasUploadedCrt(hasFile);
        } else {
            setHasUploadedKey(hasFile);
        }
        return fileList;
    };

    if (sslSettingsIsLoading) {
        return <LoadingComponent />;
    }

    return (
        <Card style={{ margin: '10px' }}>
            {
                sslBindingDtos.map((sslBindingDto, index) => {
                    return <>
                        <Card
                            title={`${translate('Domain')} ${index + 1}`}
                            style={{ margin: '10px' }}
                            key={sslBindingDto.merchantDomain}
                        >
                            <Form>
                                <FormItem label={translate('Domain')}>
                                    <Input disabled value={sslBindingDto.merchantDomain} />
                                </FormItem>
                                <FormItem label="SSL Certificate (.crt)">
                                    {
                                        <Button
                                            onClick={() => { downloadFile(`${process.env.BASE_API_URL}/api/WebSiteSetting/DownloadCertification/${merchantId}/${encodeURIComponent(sslBindingDto.merchantDomain)}`, sslBindingDto.publicKeyFileName) }}
                                            type="link">
                                            {translate('Download Certificate')}
                                        </Button>
                                    }
                                </FormItem>
                                <FormItem label="SSL Private Key (.key)">
                                    {
                                        <Button
                                            type="link"
                                            onClick={() => downloadFile(`${process.env.BASE_API_URL}/api/WebSiteSetting/DownloadPrivateKey/${merchantId}/${encodeURIComponent(sslBindingDto.merchantDomain)}`, sslBindingDto.privateKeyFilieName)}
                                        >
                                            {translate('Download Private Key')}
                                        </Button>
                                    }
                                </FormItem>
                            </Form>
                            <Flex justify="center">
                                <Popconfirm
                                    title={translate(`Delete`)}
                                    description={translate(`Are you sure to delete`) + `?`}
                                    onConfirm={(e) => {
                                        deleteSSLBindingMutate({ domain: sslBindingDto.merchantDomain })
                                    }}
                                    okText={translate('Yes')}
                                    cancelText={translate('No')}
                                >
                                    <Button
                                        danger
                                    >
                                        {translate('Delete')}
                                    </Button>
                                </Popconfirm>
                            </Flex>
                        </Card>
                    </>
                })
            }
            <Card>
                <Form
                    form={addform}
                    onFinish={handleSubmit}
                >
                    <FormItem>
                        <SSLSettingGuidePopover> {translate('Tutorial')} </SSLSettingGuidePopover>
                    </FormItem>
                    <FormItem
                        label={translate('Domain')}
                        name="merchantDomain"
                        validateStatus={!domainVlidating ? 'error' : undefined}
                        help={!domainVlidating ? <>
                            <>{translate('Invalid format')}</>
                            <> {translate('Example')}: https://www.xiaojun.com</>
                        </> : undefined}>
                        <Input
                            placeholder={translate('Domain')}
                            onChange={(value) => {
                                var validate = urlHelper.isValidDomainFormat(value.target.value);
                                setDomainVlidating(validate);
                            }}
                            autoComplete="off" />
                    </FormItem>
                    <FormItem
                        label="SSL Certificate (.crt)"
                        name="sslCertificate"
                        valuePropName="fileList"
                        getValueFromEvent={e => handleFileChange(e.fileList, 'crt')}
                        rules={[{ required: true, message: 'Please upload the SSL Certificate' }]}
                    >
                        <Upload
                            beforeUpload={file => {
                                const isValid = file.name.endsWith('.crt');
                                if (!isValid) {
                                    messageApi.error('Please upload a .crt file');
                                }
                                return isValid || Upload.LIST_IGNORE;
                            }}
                            accept=".crt"
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>Upload Certificate</Button>
                        </Upload>
                    </FormItem>
                    <FormItem
                        label="SSL Private Key (.key)"
                        name="sslPrivateKey"
                        valuePropName="fileList"
                        getValueFromEvent={e => handleFileChange(e.fileList, 'key')}
                        rules={[{ required: true, message: 'Please upload the SSL Private Key' }]}
                    >
                        <Upload
                            beforeUpload={file => {
                                const isValid = file.name.endsWith('.key');
                                if (!isValid) {
                                    messageApi.error('Please upload a .key file');
                                }
                                return isValid || Upload.LIST_IGNORE;
                            }}
                            accept=".key"
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>Upload Private Key</Button>
                        </Upload>
                    </FormItem>
                    <Flex justify="center">
                        <Button
                            htmlType="submit"
                            disabled={!hasUploadedCrt || !hasUploadedKey || !domainVlidating}
                        >
                            {translate('Save')}
                        </Button>
                    </Flex>
                </Form>
            </Card>
        </Card>
    );
});

export default SSLSettings;
