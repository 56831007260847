import { DeviceType } from '@/Templates/enums/templateEnums';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { IGlobalContextType, IGlobalProviderProps } from '../../interfaces/GlobalContextType';
import { useGetMyRolesApi } from '../api/permission';
import { useGetMerchantPortalOptionSettingApi, useGetMerchantPortalStyleSettingsApi } from '../api/portals';
import { useGetRolesApi } from '../api/token';
import { useGetSSLBindingApi } from '../api/webSiteSettings';
import { GenereateHelper } from '../helpers/GenerateHelper';

export const GlobalContext = React.createContext<IGlobalContextType>(null!);

export const GlobalProvider: React.FC<IGlobalProviderProps> = ({ messageApi, children }): React.ReactNode => {
  const navigate = useNavigate();
  const generateHelper = useMemo(() => new GenereateHelper(), []);
  const { t, i18n } = useTranslation('Global');
  const [hasMerchant, setHasMerchant] = useState<boolean>(false);
  const [merchantId, setMerchantId] = useState<BigInt | undefined>(undefined);
  const [identityRoles, setIdentityRoles] = useState<string[] | undefined>(undefined);
  const [merchantPermissionRoles, setMerchantPermissionRoles] = useState<string[] | undefined>(undefined);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [systemConfig, setSystemConfig] = useState<IGlobalContextType['systemConfig']>({ Roles: [] });
  const [isHideNavBar, setIsHideNavBar] = useState<IGlobalContextType['isHideNavBar']>(false);
  const [isHideAnnouncement, setIsHideAnnouncement] = useState<IGlobalContextType['isHideAnnouncement']>(true);
  const [navBarHeight, setNavBarHeight] = useState<IGlobalContextType['navBarHeight']>(0);
  const [isPageLoading, setIsPageLoading] = useState<IGlobalContextType['isPageLoading']>(false);
  const [merchantPortalOptionSetting, setMerchantPortalOptionSetting] = useState<IGlobalContextType['merchantPortalOptionSetting']>(undefined);
  const [isLogin, setIsLogin] = useState<IGlobalContextType['isLogin']>(() => {
    const token = localStorage.getItem("JWTToken");
    return token !== null;
  });
  const [deviceType, setDeviceType] = useState<IGlobalContextType['deviceType']>(DeviceType.Desktop);
  const [portalLink, setPortalLink] = useState<string>('');
  const [merchantPortalStyleSettings, setMerchantPortalStyleSettings] = useState<IGlobalContextType['merchantPortalStyleSettings']>([]);

  const { data: rolesData, refetch: refetchIdentityRoles } = useQuery(
    'identityRoles',
    useGetRolesApi,
    {
      enabled: false,
      onSuccess: (response) => {
        if (response.isSuccess) {
          setIdentityRoles(response.result);
        }
        else {
          setIdentityRoles([]);
        }
        setIsInitialized(true);
      },
      onError: () => {
        // 服務出錯時，導向登入頁
        console.error('Error fetching roles');
        navigate('/login');
      }
    }
  );

  const { refetch: refetchMerchantPermissionRoles } = useQuery(
    'merchantPermissionRoles',
    () => useGetMyRolesApi(undefined),
    {
      enabled: false,
      onSuccess: (response) => {
        if (response.isSuccess) {
          setMerchantPermissionRoles(response.result);
        }
        else {
          setMerchantPermissionRoles([]);
        }
      }
    }
  );

  const getMerchantPortalOptionSetting = useMutation(
    (params: { merchantId: BigInt, signal?: AbortSignal }) =>
      useGetMerchantPortalOptionSettingApi(params.merchantId, params.signal),
    {
      onSuccess: (response) => {
        if (response.isSuccess) {
          setMerchantPortalOptionSetting(response.result);
        } else {
          messageApi.error(t(response.message || 'Operation failed'));
        }
      }
    }
  );

  const getMerchantPortalStyleSettings = useMutation(
    (params: { merchantId: BigInt, signal?: AbortSignal }) =>
      useGetMerchantPortalStyleSettingsApi(params.merchantId, params.signal),
    {
      onSuccess: (response) => {
        if (response.isSuccess) {
          setMerchantPortalStyleSettings(response.result || []);
        } else {
          messageApi.error(t(response.message || 'Operation failed'));
        }
      }
    }
  );

  const updateSystemConfig = useCallback((config: { Roles: string[] }) => {
    setSystemConfig(config);
  }, []);

  const updateNavBarHeight = useCallback((height: number) => {
    setNavBarHeight(height);
  }, []);

  const updateIsLogin = useCallback((isLogin: boolean) => {
    setIsLogin(isLogin);
  }, []);

  const updateDeviceType = useCallback((deviceType: DeviceType) => {
    setDeviceType(deviceType);
  }, []);

  const getMerchantIdFromLocalStorage = (): string | null => {
    return localStorage.getItem('MerchantId');
  };

  const tryGetPortalLink = useCallback(async (merchantId: string): Promise<void> => {
    try {
      const response = await useGetSSLBindingApi();
      if (response && response.result && response.result.length > 0) {
        setPortalLink(response.result[0].merchantDomain);
      } else {
        const portalHostName = window.location.hostname.replace('backoffice', 'portal');
        const defaultPortalLink = `https://${portalHostName}${process.env.ASPNETCORE_ENVIRONMENT === 'Production' ? '' : ':5173'}/${merchantId}/`;
        setPortalLink(defaultPortalLink);
      }
    } catch (error) {
      console.error('Error fetching SSL binding:', error);
      messageApi.error('Operation failed');
    }
  }, [messageApi]);

  const setMerchantIdAggregate = useCallback((merchantId: BigInt) => {
    localStorage.setItem('MerchantId', merchantId.toString());
    setMerchantId(merchantId);
    setHasMerchant(true);
    getMerchantPortalOptionSetting.mutate({ merchantId });
    tryGetPortalLink(merchantId.toString());
    refetchIdentityRoles();  // 触发角色获取
    refetchMerchantPermissionRoles(); // 触发权限角色获取
  }, [getMerchantPortalOptionSetting.mutate, tryGetPortalLink, refetchIdentityRoles, refetchMerchantPermissionRoles]);

  const removeMerchantIdAggregate = useCallback(() => {
    localStorage.removeItem('MerchantId');
    setMerchantId(undefined);
  }, []);

  useEffect(() => {
    const storedMerchantId = localStorage.getItem('MerchantId');
    if (storedMerchantId) {
      setMerchantId(BigInt(storedMerchantId));
      refetchIdentityRoles();  // 初始化时获取角色
    }
  }, [refetchIdentityRoles]);

  return (
    <GlobalContext.Provider value={{
      generateHelper,
      messageApi,
      hasMerchant,
      setHasMerchant,
      merchantId,
      getMerchantIdFromLocalStorage,
      setMerchantIdAggregate,
      removeMerchantIdAggregate,
      identityRoles: identityRoles,
      setIdentityRoles: setIdentityRoles,
      merchantPermissionRoles: merchantPermissionRoles,
      setMerchantPermissionRoles: setMerchantPermissionRoles,
      isInitialized,
      isLogin,
      setIsLogin: updateIsLogin,
      systemConfig,
      setSystemConfig: updateSystemConfig,
      isHideNavBar,
      setIsHideNavBar,
      navBarHeight,
      setNavBarHeight: updateNavBarHeight,
      isHideAnnouncement,
      setIsHideAnnouncement,
      isPageLoading,
      setIsPageLoading,
      deviceType,
      setDeviceType: updateDeviceType,
      merchantPortalOptionSetting,
      getMerchantPortalOptionSetting,
      portalLink,
      tryGetPortalLink,
      merchantPortalStyleSettings,
      getMerchantPortalStyleSettings,
    }}>
      {children}
    </GlobalContext.Provider>
  );
};
