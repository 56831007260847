import { IUserInfoViewModel } from "@/interfaces/Responses/User";
import { useGetUserInfoApi } from "@/lib/api/user";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { CopyOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Empty, Spin, Tabs, Typography, message } from "antd";
import React, { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import MyAssistantInvitesList from "../AssistantManagePages/Components/MyAssistantInvitesList";

const { Text } = Typography;
const { TabPane } = Tabs;

const UserInfoPage: React.FC = () => {
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);

    const { mutate: getUserInfo, data: userInfo, isLoading, isError } = useMutation(useGetUserInfoApi, {
        onError: (error) => {
            messageApi.error(translate('Failed to fetch user information'));
        }
    });

    useEffect(() => {
        getUserInfo();
    }, [getUserInfo]);

    const handleRefresh = () => {
        getUserInfo();
    };

    const isEmptyOrWhitespace = (str: string | undefined): boolean => {
        return str === undefined || str.trim() === '';
    };

    const renderFieldValue = (value: string | undefined): React.ReactNode => {
        return isEmptyOrWhitespace(value) ? <Text type="secondary">{translate('Unset')}</Text> : value;
    };

    const handleCopyUserId = (userId: string) => {
        navigator.clipboard.writeText(userId).then(() => {
            message.success(translate('Copied to clipboard'));
        }).catch(() => {
            message.error(translate('Failed to copy User ID'));
        });
    };

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Spin size="large" />
            </div>
        );
    }

    if (isError) {
        return (
            <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <Text type="danger">{translate('Error loading user information')}</Text>
                <br />
                <Button onClick={handleRefresh} icon={<ReloadOutlined />} style={{ marginTop: '20px' }}>
                    {translate('Try Again')}
                </Button>
            </div>
        );
    }

    const userInfoData: IUserInfoViewModel | undefined = userInfo?.result;

    return (
        <div style={{ padding: '24px' }}>
            <Card
                title={`${translate('User')} ${translate('Information')}`}
                extra={<Button icon={<ReloadOutlined />} onClick={handleRefresh}>{translate('Refresh')}</Button>}
                style={{ maxWidth: 500, margin: '0 auto 20px' }}
            >
                {userInfoData ? (
                    <>
                        <p>
                            <strong>{translate('User ID')}:</strong>{' '}
                            {renderFieldValue(userInfoData.identityUserId)}{' '}
                            {!isEmptyOrWhitespace(userInfoData.identityUserId) && (
                                <CopyOutlined
                                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                                    onClick={() => handleCopyUserId(userInfoData.identityUserId)}
                                />
                            )}
                        </p>
                        <p><strong>{translate('User Name')}:</strong> {renderFieldValue(userInfoData.userName)}</p>
                        <p><strong>{translate('Email')}:</strong> {renderFieldValue(userInfoData.email)}</p>
                        <p><strong>{translate('Phone Number')}:</strong> {renderFieldValue(userInfoData.phoneNumber)}</p>
                    </>
                ) : (
                    <Text type="secondary">{translate('No user information available')}</Text>
                )}
            </Card>
            <Tabs defaultActiveKey="1" centered style={{ maxWidth: 800, margin: '0 auto' }}>
                <TabPane tab={`${translate('Assistant')} ${translate('invitations')}`} key="1">
                    <MyAssistantInvitesList />
                </TabPane>
                <TabPane tab={translate('Messages')} key="2">
                    <Empty description={
                        translate('No data')
                    }>
                    </Empty>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default UserInfoPage;