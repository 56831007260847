import { OrderStatus } from "@/enums/Enums";
import { IRecentOrdersByStatusViewModel, MerchantGoogleIntegrateSetting } from "@/interfaces/Responses/DataAnalysis";
import { IOperationResult, IOperationResultT } from "@/interfaces/Responses/Responses";
import useAPI from "../customHooks/useAPI";

export const useGetMerchantGoogleIntegrateSettingApi = (signal?: AbortSignal) => useAPI<IOperationResultT<MerchantGoogleIntegrateSetting>>(`${process.env.BASE_API_URL}/api/DataAnalysis/GetMerchantGoogleIntegrateSetting`, {
    signal
});

export const useAlterMerchantGoogleIntegrateSettingApi = (request: MerchantGoogleIntegrateSetting) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/DataAnalysis/AlterMerchantGoogleIntegrateSetting`, {
    method: 'POST',
    body: request,
});

export const useGetRecentOrdersByStatusApi = (status: OrderStatus = OrderStatus.Done, days: number = 7, signal?: AbortSignal) => useAPI<IOperationResultT<IRecentOrdersByStatusViewModel[]>>(`${process.env.BASE_API_URL}/api/DataAnalysis/GetRecentOrdersByStatus?status=${status}&days=${days}`, {
    signal
});
