import { IOperationResultT } from "@/interfaces/Responses/Responses";
import { useThirdPartyLoginApi } from "@/lib/api/thirdPartyLogin";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LineLogin from '@/lib/thirdParty/LineLogin';
import LoadingComponent from '@/Templates/components/LoadingCompoent';
import { FacebookOutlined, GoogleOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Divider, Flex, Form, Input, InputRef } from "antd";
import FormItem from "antd/es/form/FormItem";
import { MD5 } from "crypto-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from 'react-query';
import { useNavigate } from "react-router-dom";
import { LoginTypeEnum, ThirdPartyLoginProviders } from '../../enums/Enums';
import { IBackOfficeThirdPartyLoginRequest, ILoginRequest } from '../../interfaces/Requests/Requests';
import { useMerchantLoginApi } from "../../lib/api/merchants";
import { GlobalContext } from '../../lib/contexts/GlobalContext';
const LoginPage = () => {
  const { translate } = useContext(TranslationContext);
  const { messageApi, setIsLogin, setIsHideNavBar, removeMerchantIdAggregate } = useContext(GlobalContext);
  const emailRef = useRef<InputRef>(null);
  const [loginRequest, setLoginRequest] = useState<ILoginRequest>({
    loginType: LoginTypeEnum.Email,
    email: process.env.TEST_ACCOUNT ? process.env.TEST_ACCOUNT : "",
    hashPassword: process.env.TEST_PASSWORD ? MD5(`${process.env.TEST_PASSWORD}`).toString().toLowerCase() : "",
    phoneNumber: "",
  });
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (codeResponse) => {
      var request: IBackOfficeThirdPartyLoginRequest = {
        provider: ThirdPartyLoginProviders.Google,
        accessToken: codeResponse.code
      };
      thirdPartyLoginMutate(request);
    },
    onError: errorResponse => console.log(errorResponse),
  });
  const facebookLogin = () => {
    FacebookLoginClient.login((codeResponse) => {
      if (codeResponse.status !== 'connected') {
        messageApi.error('Operation failed');
        return;
      }
      var request: IBackOfficeThirdPartyLoginRequest = {
        provider: ThirdPartyLoginProviders.Facebook,
        accessToken: codeResponse.authResponse?.accessToken!
      };
      thirdPartyLoginMutate(request);
    },
      {
        scope: 'public_profile, email',
      });
  };
  const loginResponseHandler = (data: IOperationResultT<string>) => {
    if (data?.isSuccess === true) {
      localStorage.setItem("JWTToken", data.result!); //SET JWTToken
      removeMerchantIdAggregate();
      setIsLogin(true);
      messageApi.success(translate('Operation success'));
      setIsHideNavBar(false);
      navigate(`/`, { replace: true });
    }
    else {
      messageApi.error(data?.message);
    }
  }
  const { data, mutate: memberLoginMutate, isLoading: loginMutating } = useMutation(async (request: ILoginRequest) => await useMerchantLoginApi(request), {
    onSuccess: (data) => {
      loginResponseHandler(data);
    }
  });
  const { mutate: thirdPartyLoginMutate, isLoading: thirdPartyLoginMutating } = useMutation(async (reqeust: IBackOfficeThirdPartyLoginRequest) => await useThirdPartyLoginApi(reqeust), {
    onSuccess: (data) => {
      loginResponseHandler(data);
    }
  });
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const handelChange = (type: any, value: any) => {
    setLoginRequest({ ...loginRequest, [type]: value });
  };
  const token = localStorage.getItem("JWTToken");
  const inputRef = useRef<HTMLInputElement>(null);
  const focusInput = () => {
    // 檢查 inputRef.current 是否存在
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  function handelSubmit(value: any) {
    memberLoginMutate(loginRequest)
  };


  useEffect(() => {
    setIsHideNavBar(true);
    FacebookLoginClient.loadSdk('en_US').then(() => {
      FacebookLoginClient.init({ appId: `${process.env.FacebookAppId}`, version: 'v16.0' });
    });
    if (token !== null) {
      navigate(`/login`, { replace: true });
    }
  }, []);

  return (
    <div className="flex justify-center items-center h-[100vh]">
      {(thirdPartyLoginMutating || loginMutating) &&
        <LoadingComponent>
        </LoadingComponent>
      }
      {!(thirdPartyLoginMutating || loginMutating) &&
        <Form
          form={form}
          name="login-form"
          initialValues={{
            email: loginRequest.email,
            hashPassword: loginRequest.hashPassword,
          }}
        >
          <FormItem
            name="email"
            rules={[{ required: true }, { message: "please input your email!" }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="email"
              ref={emailRef}
              value={loginRequest.email}
              onChange={(e) => handelChange("email", e.target.value)}
            />
          </FormItem>
          <FormItem
            name="hashPassword"
            rules={[
              { required: true },
              { message: "please input your hashPassword!" },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="password"
              value={loginRequest.hashPassword}
              onChange={(e) => handelChange("hashPassword", MD5(e.target.value).toString().toLowerCase())}
            />
          </FormItem>
          <FormItem>
            <Flex justify="center">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button mr-2"
                loading={thirdPartyLoginMutating || loginMutating}
                onClick={(value) => handelSubmit(value)}
              >
                {translate("Login")}
              </Button>
            </Flex>
          </FormItem>
          <Divider>{translate('Third Party') + ' ' + translate('Login')}</Divider>
          <Flex>
            <Button
              loading={thirdPartyLoginMutating || loginMutating}
              onClick={() => googleLogin()}>
              <GoogleOutlined />Google
            </Button>
            <Button
              loading={thirdPartyLoginMutating || loginMutating}
              onClick={() => facebookLogin()}>
              <FacebookOutlined />Facebook
            </Button>
            <LineLogin
              tokenCallback={(token) => {
                var request: IBackOfficeThirdPartyLoginRequest = {
                  provider: ThirdPartyLoginProviders.Line,
                  accessToken: token,
                  extraData: 'https://backoffice.junxiaotech.com/line-login'
                };
                //prevent mutiple re-render
                navigate('/login', { replace: true });

                thirdPartyLoginMutate(request);
              }}
            ></LineLogin>
          </Flex>
        </Form>
      }
    </div >
  );
};

export default LoginPage;
