import { IDeleteDomainBindingsRequest, IDeleteMerchantSSLRequest, IUpsertDomainBindingsRequest } from "@/interfaces/Requests/Requests";
import { IOperationResult, IOperationResultT, ISSLBindingDto } from "@/interfaces/Responses/Responses";
import useAPI from "../customHooks/useAPI";


export const useInsertDomainBindingsApi = (request: IUpsertDomainBindingsRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/WebSiteSetting/InsertDomainBindings`, {
    method: 'POST',
    body: request
});

export const useDeleteDomainBindingApi = (request: IDeleteDomainBindingsRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/WebSiteSetting/DeleteDomainBindings`, {
    method: 'POST',
    body: request
});

export const useGetSSLBindingApi = (signal?: AbortSignal) => useAPI<IOperationResultT<ISSLBindingDto[]>>(`${process.env.BASE_API_URL}/api/WebSiteSetting/GetSSLBinding`, {
    method: 'GET',
    signal: signal
});

export const useUpsertMerchantSSLApi = (params: FormData) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/WebSiteSetting/UpsertMerchantSSL`, {
    method: 'POST',
    body: params,
    contentType: 'multipart/form-data'
});

export const useDeleteDeleteMerchantSSLApi = (request: IDeleteMerchantSSLRequest) => useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/WebSiteSetting/DeleteMerchantSSL`, {
    method: 'POST',
    body: request
});