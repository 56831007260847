import { useGetDefaultPortalStyleTemplateApi } from '@/lib/api/frontendsettings';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { MerchantPortalStyleShopComponents, MerchantPortalStyleShopPages } from '@/Templates/enums/templateEnums';
import { javascript } from '@codemirror/lang-javascript';
import CodeMirror from '@uiw/react-codemirror';
import { Button, Flex, Modal, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import UIPreviewComponent from './UIPreviewComponent';

const { Option } = Select;

interface CodeEditorComponentProps {
  onCodeChange: (code: string) => void;
}

const CodeEditorComponent: React.FC<CodeEditorComponentProps> = ({ onCodeChange }) => {
  const { messageApi } = useContext(GlobalContext);
  const { translate } = useContext(TranslationContext);
  const [code, setCode] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [mainCategory, setMainCategory] = useState<'Pages' | 'Components'>('Pages');
  const [subCategory, setSubCategory] = useState<MerchantPortalStyleShopPages | MerchantPortalStyleShopComponents | null>(null);

  const { mutateAsync, isLoading } = useMutation(
    async ({ type, name }: { type: string; name: string }) => {
      return await useGetDefaultPortalStyleTemplateApi(type, name);
    },
    {
      onSuccess: (data) => {
        setCode(data.result || '');
        onCodeChange(data.result || '');
      },
    }
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (!subCategory) {
      messageApi.error('请选择子分类');
      return;
    }
    Modal.confirm({
      title: translate('确认套用模板'),
      content: translate('套用新模板将会覆盖当前代码，是否继续？'),
      onOk: async () => {
        try {
          await mutateAsync({ type: mainCategory, name: subCategory });
          setIsModalVisible(false);
          messageApi.success('模板套用成功');
        } catch (error) {
          console.error('加载模板失败:', error);
          messageApi.error('模板套用失败');
        }
      },
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ width: '100%', marginTop: '16px' }}>
      <Flex justify="center">
        <Button onClick={showModal}>{translate('获取模板')}</Button>
      </Flex>
      <Modal
        title={translate('选择模板')}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Select
          style={{ width: '100%', marginBottom: 16 }}
          value={mainCategory}
          onChange={(value: 'Pages' | 'Components') => {
            setMainCategory(value);
            setSubCategory(null);
          }}
        >
          <Option value="Pages">{translate('Pages')}</Option>
          <Option value="Components">{translate('Components')}</Option>
        </Select>
        <Select
          style={{ width: '100%' }}
          value={subCategory}
          onChange={(value: MerchantPortalStyleShopPages | MerchantPortalStyleShopComponents) => setSubCategory(value)}
        >
          {mainCategory === 'Pages'
            ? Object.values(MerchantPortalStyleShopPages).map((pageType) => (
              <Option key={pageType} value={pageType}>{translate(pageType)}</Option>
            ))
            : Object.values(MerchantPortalStyleShopComponents).map((componentType) => (
              <Option key={componentType} value={componentType}>{translate(componentType)}</Option>
            ))
          }
        </Select>
      </Modal>
      <CodeMirror
        value={code}
        height="400px"
        extensions={[javascript({ jsx: true, typescript: true })]}
        onChange={(value) => {
          setCode(value);
          onCodeChange(value);
        }}
        editable={!isLoading}
      />
      <Flex justify="center">
        <UIPreviewComponent code={code} />
      </Flex>
      <Flex justify="center">
        <Button onClick={handleOk}>{translate('确定')}</Button>
      </Flex>
    </div>
  );
};

export default CodeEditorComponent;