import { ItemContext } from "@/lib/contexts/ItemContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Flex } from "antd";
import Title from "antd/es/typography/Title";
import { load } from "cheerio";
import ImageResize from 'quill-image-resize-module-react';
import React, { useContext } from "react";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
export interface IDescriptionProps {
    previewOnly?: boolean;
}
export interface IDescriptionRef {
    validate: () => Promise<boolean>;
}


Quill.register('modules/imageResize', ImageResize);
const AlterDescription: React.FC<IDescriptionProps> = ({
    previewOnly
}) => {
    const { itemViewModel, setItemViewModel } = useContext(ItemContext);
    const { translate } = useContext(TranslationContext);
    var modules: any =
    {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['clean']
        ],
    };
    if (!previewOnly) {
        modules = {
            ...modules,
            imageResize: {
                parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
            }
        }
    }
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent', 'size', 'color', 'background', 'font', 'align',
        'link', 'image'
    ];

    const onDescriptionChange = (value: string) => {
        const $ = load(value);
        // Add max-width: 100% to all elements
        $('*').each((index, element) => {
            const existingStyle = $(element).attr('style') || '';
            const newStyle = `${existingStyle} max-width: 100%;`;
            $(element).attr('style', newStyle);
        });
        return $.html();
    }

    return (
        <div style={{ width: '100%' }}>
            <Flex justify="center">
                <Title level={3}>{translate('Description')}</Title>
            </Flex>
            <ReactQuill
                readOnly={previewOnly}
                theme="snow"
                style={{ padding: '30px', width: '100%' }}
                value={itemViewModel.description}
                onChange={(value) => {
                    itemViewModel.description = onDescriptionChange(value);
                }}
                modules={modules}
                formats={formats}
                placeholder={translate('Write something amazing...')}
            />
        </div>
    );
};
export default AlterDescription;