import { LocalStorageConstants } from '@/constants/localStorageConstant';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import NavBar from '@/pages/AppPages/Components/NavBar';
import ItemCard from '@/Templates/components/ItemCard';
import { DeviceType, MarqueeType, MerchantCheckoutType, MerchantPortalStyleSettingType, MerchantPortalStyleShopComponents, MerchantPortalStyleShopPages, NavBarClickType } from '@/Templates/enums/templateEnums';
import { IItemViewModel, IMerchantPortalOptionSettingViewModel, IMerchantPortalStyleSetting, INavBarOptionDto, IPaginationResponse, MerchantMarqueeSettingViewModel } from '@/Templates/interfaces/templatesInterfaces';
import { ItemCardTemplateProps, NavBarTemplateProps, PagesTemplateProps, TemplateProps } from '@/Templates/TemplateProps';
import * as antdIcons from '@ant-design/icons';
import * as antdProForm from '@ant-design/pro-form';
import { transform } from '@babel/standalone';
import * as antd from 'antd';
import * as framerMotion from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import * as reactRouterDom from 'react-router-dom';
import * as uuid from 'uuid';

declare global {
    interface Window {
        UI_Preview_Only_LocalStorageConstants: typeof LocalStorageConstants;
        UI_Preview_Only_DeviceType: typeof DeviceType;
        UI_Preview_Only_MerchantCheckoutType: typeof MerchantCheckoutType;
        UI_Preview_Only_NavBarClickType: typeof NavBarClickType;
        UI_Preview_Only_MerchantPortalStyleSettingType: typeof MerchantPortalStyleSettingType;
        UI_Preview_Only_MerchantPortalStyleShopPages: typeof MerchantPortalStyleShopPages;
        UI_Preview_Only_MerchantPortalStyleShopComponents: typeof MerchantPortalStyleShopComponents;
        UI_Preview_Only_INavBarOptionDto: INavBarOptionDto;
        UI_Preview_Only_IMerchantPortalStyleSetting: IMerchantPortalStyleSetting;
        UI_Preview_Only_IMerchantPortalOptionSettingViewModel: IMerchantPortalOptionSettingViewModel;
        UI_Preview_Only_IItemViewModel: IItemViewModel;
        UI_Preview_Only_IPaginationResponse: IPaginationResponse<any>;
        UI_Preview_Only_TemplateProps: TemplateProps;
        UI_Preview_Only_PagesTemplateProps: PagesTemplateProps;
        UI_Preview_Only_NavBarTemplateProps: NavBarTemplateProps;
        UI_Preview_Only_ItemCardTemplateProps: ItemCardTemplateProps;
        UI_Preview_Only_MessageApi: any;
        UI_Preview_Only_translate: (key: string, nameSpace?: string) => string;
        UI_Preview_Only_MerchantMarqueeSettingViewModel: MerchantMarqueeSettingViewModel;
    }
}

interface UIPreviewComponentProps {
    code: string;
}

class ErrorBoundary extends React.Component<{ children: React.ReactNode, fallback: React.ReactNode }, { hasError: boolean, error: Error | null }> {
    constructor(props: { children: React.ReactNode, fallback: React.ReactNode }) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error("捕获到未处理的错误:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ color: 'red', padding: '10px', border: '1px solid red' }}>
                    <h3>渲染错误</h3>
                    <p>{this.state.error?.message}</p>
                    <details>
                        <summary>错误详情</summary>
                        <pre>{this.state.error?.stack}</pre>
                    </details>
                </div>
            );
        }
        return this.props.children;
    }
}

// UIPreviewComponent: 用于预览和渲染动态生成的React组件
const UIPreviewComponent: React.FC<UIPreviewComponentProps> = ({ code }) => {
    // 状态管理
    const [Component, setComponent] = useState<React.FC | null>(null); // 存储动态创建的组件
    const [error, setError] = useState<string | null>(null); // 存储错误信息
    const [propsType, setPropsType] = useState<string | null>(null); // 存储组件的props类型
    const [componentName, setComponentName] = useState<string | null>(null); // 存储组件名称
    const { messageApi } = useContext(GlobalContext); // 获取全局消息API
    const { translate } = useContext(TranslationContext); // 获取翻译函数

    const [key, setKey] = useState(0); // 用于强制重新渲染组件

    // 初始化全局对象，用于模拟组件运行环境
    const initializeGlobalObjects = () => {
        const window = globalThis as any;
        window.UI_Preview_Only_LocalStorageConstants = LocalStorageConstants;
        window.UI_Preview_Only_DeviceType = DeviceType;
        window.UI_Preview_Only_MerchantCheckoutType = MerchantCheckoutType;
        window.UI_Preview_Only_NavBarClickType = NavBarClickType;
        window.UI_Preview_Only_MerchantPortalStyleSettingType = MerchantPortalStyleSettingType;
        window.UI_Preview_Only_MerchantPortalStyleShopPages = MerchantPortalStyleShopPages;
        window.UI_Preview_Only_MerchantPortalStyleShopComponents = MerchantPortalStyleShopComponents;

        window.UI_Preview_Only_INavBarOptionDto = createNavBarOptionDto();
        window.UI_Preview_Only_IMerchantPortalStyleSetting = createMerchantPortalStyleSetting();
        window.UI_Preview_Only_IMerchantPortalOptionSettingViewModel = createMerchantPortalOptionSettingViewModel();
        window.UI_Preview_Only_IItemViewModel = createItemViewModel();
        window.UI_Preview_Only_IPaginationResponse = createPaginationResponse();
        window.UI_Preview_Only_TemplateProps = createTemplateProps();
        window.UI_Preview_Only_PagesTemplateProps = createPagesTemplateProps();
        window.UI_Preview_Only_NavBarTemplateProps = createNavBarTemplateProps();
        window.UI_Preview_Only_ItemCardTemplateProps = createItemCardTemplateProps();
        window.UI_Preview_Only_MessageApi = messageApi;
        window.UI_Preview_Only_translate = translate;
        window.UI_Preview_Only_MerchantMarqueeSettingViewModel = createMerchantMarqueeSettingViewModel();
    };

    // 创建各种模拟数据的函数
    const createNavBarOptionDto = (): INavBarOptionDto => ({
        id: BigInt(1),
        merchantId: BigInt(1),
        name: "测试导航",
        type: NavBarClickType.None,
        extraInfo: "",
        isDeleted: false,
        orderIndex: 1,
        childrenIds: []
    });

    const createMerchantPortalStyleSetting = (): IMerchantPortalStyleSetting => ({
        id: BigInt(1),
        merchantId: BigInt(1),
        type: MerchantPortalStyleSettingType.Component,
        name: "测试样式",
        style: 1,
        styleSetting: {}
    });

    const createMerchantPortalOptionSettingViewModel = (): IMerchantPortalOptionSettingViewModel => ({
        merchantId: BigInt(1),
        merchantName: "测试商家",
        country: "中国",
        merchantIconPath: "",
        portalLanguage: "zh-CN",
        merchantCheckoutType: MerchantCheckoutType.Normal,
        isEnableInovice: false,
        isEnableMainMarquee: false,
        updatedDate: new Date().toISOString(),
        orderMinimumAmountLimit: 0,
        isCustomLandingPage: false,
        isCustomPortalPage: false
    });

    const createItemViewModel = (): IItemViewModel => ({
        id: BigInt(1),
        name: "测试商品",
        title: "测试标题",
        briefly: "简短描述",
        description: "详细描述",
        createdDate: new Date().toISOString(),
        alterDate: new Date().toISOString(),
        isDelete: false,
        isOnShelf: true,
        itemConverImagePaths: ["https://storage.googleapis.com/microshop-images-bucket/system/TestItem.jpg"],
        itemTags: [],
        minPrice: 100,
        maxPrice: 200
    });

    const createPaginationResponse = (): IPaginationResponse<any> => ({
        totalCount: 1,
        totalPage: 1,
        data: [createItemViewModel()]
    });

    const createMerchantMarqueeSettingViewModel = (): MerchantMarqueeSettingViewModel => ({
        id: BigInt(1),
        merchantId: BigInt(1),
        type: MarqueeType.MerchantMain,
        imagePaths: ["https://storage.googleapis.com/microshop-images-bucket/system/TestItem.jpg"],
        imageIds: [],
        externalLinks: [],
        createdDate: new Date().toISOString(),
    });

    const createTemplateProps = (): TemplateProps => ({
        merchantId: BigInt(1),
        config: {},
        translate: translate,
        isPreviewMode: true,
        portalOptionSettingViewModel: createMerchantPortalOptionSettingViewModel(),
        portalStyleSettings: [createMerchantPortalStyleSetting()],
        messageApi: messageApi,
    });

    const createPagesTemplateProps = (): PagesTemplateProps => ({
        ...createTemplateProps(),
        deviceType: DeviceType.Desktop,
        items: createPaginationResponse(),
        queryState: {
            options: {},
            form: undefined,
            request: {},
            setRequest: () => { },
            search: () => { },
            loading: false,
            fuzzingName: "",
            categoryIds: [],
            itemTagIds: [],
            onFormValuesChange: () => { },
            onSearch: () => { },
            isLoading: false
        },
        mainMarqueeViewModel: createMerchantMarqueeSettingViewModel()
    });

    const createNavBarTemplateProps = (): NavBarTemplateProps => ({
        ...createTemplateProps(),
        BASE_PATH: "/",
        menuItems: [createNavBarOptionDto()],
        isHideNavBar: false,
        merchantPortalOptionSetting: createMerchantPortalOptionSettingViewModel(),
        navBarHeight: 60,
        deviceType: DeviceType.Desktop,
        shoppingCartItemCount: 0,
        isLogin: false,
        setNavBarHeight: () => { },
        setNavBarGap: () => { },
        setDeviceType: () => { },
        setIsLogin: () => { },
        refreshShoppingCartItemCount: () => { }
    });

    const createItemCardTemplateProps = (): ItemCardTemplateProps => ({
        ...createTemplateProps(),
        item: createItemViewModel(),
        deviceType: DeviceType.Desktop,
        isloading: false
    });

    // 转换代码：移除import语句，提取props类型和组件名称
    const transformCode = (code: string): { transformedCode: string, propsType: string | null, componentName: string | null } => {
        const propsTypeMatch = code.match(/React\.FC<(\w+)>/);
        const propsType = propsTypeMatch ? propsTypeMatch[1] : null;

        // 提取默认导出的组件名称
        const exportDefaultMatch = code.match(/export\s+default\s+(\w+)/);
        const componentName = exportDefaultMatch ? exportDefaultMatch[1] : null;

        // 移除所有 export 语句
        const codeWithoutExports = code.replace(/export\s+(const|function|class|default)\s+/g, '');

        // 移除 import 语句
        const codeWithoutImports = codeWithoutExports.replace(/^import\s+(?:(?:(?:[\w*\s{},]*)\s+from\s+)?(?:(?:".*?")|(?:'.*?'))[\s]*[;]?)/gm, '');

        const transformedCode = transform(codeWithoutImports, {
            presets: [['typescript', { allExtensions: true, isTSX: true }], 'react'],
            plugins: ['proposal-class-properties', 'proposal-object-rest-spread'],
        }).code;

        return { transformedCode, propsType, componentName };
    };

    // 创建动态组件
    const createComponent = (transformedCode: string, propsType: string | null, componentName: string | null): React.FC<any> | null => {
        if (!componentName) {
            throw new Error('未找到导出的组件名称');
        }

        try {
            const wrappedCode = `
                const { useState, useEffect, useContext, useCallback, useMemo, useRef ,forwardRef} = React;
                const { messageApi } = window.UI_Preview_Only_MessageApi;
                ${transformedCode}
                return ${componentName} || Template;
            `;
            //载入套件
            const ComponentFunction = new Function(
                'React',
                'antd',
                'antdProForm',
                'antdIcons',
                'framerMotion',
                'uuid',
                'reactRouterDom',
                'props',
                'ItemCard',
                'NavBar',
                'DeviceType',
                'MerchantCheckoutType',
                'NavBarClickType',
                'MerchantPortalStyleSettingType',
                'MerchantPortalStyleShopComponents',
                'MerchantPortalStyleShopPages',
                'IItemViewModel',
                'IMerchantPortalOptionSettingViewModel',
                'IMerchantPortalStyleSetting',
                'INavBarOptionDto',
                'IPaginationResponse',
                'ItemCardTemplateProps',
                'NavBarTemplateProps',
                'PagesTemplateProps',
                'TemplateProps',
                'translate',
                wrappedCode
            );

            // 创建一个有效的 React 函数组件
            const WrappedComponent: React.FC = function (componentProps: any) {
                try {
                    const propKey = `UI_Preview_Only_${propsType}`;
                    const props = (window as any)[propKey] || {};
                    const mergedProps = { ...props, ...componentProps };
                    console.log('渲染组件时的props:', mergedProps);
                    // 加载依赖项
                    return ComponentFunction(
                        React,
                        antd,
                        antdProForm,
                        antdIcons,
                        framerMotion,
                        uuid,
                        reactRouterDom,
                        mergedProps,
                        ItemCard,
                        NavBar,
                        DeviceType,
                        MerchantCheckoutType,
                        NavBarClickType,
                        MerchantPortalStyleSettingType,
                        MerchantPortalStyleShopComponents,
                        MerchantPortalStyleShopPages,
                        window.UI_Preview_Only_IItemViewModel,
                        window.UI_Preview_Only_IMerchantPortalOptionSettingViewModel,
                        window.UI_Preview_Only_IMerchantPortalStyleSetting,
                        window.UI_Preview_Only_INavBarOptionDto,
                        window.UI_Preview_Only_IPaginationResponse,
                        window.UI_Preview_Only_ItemCardTemplateProps,
                        window.UI_Preview_Only_NavBarTemplateProps,
                        window.UI_Preview_Only_PagesTemplateProps,
                        window.UI_Preview_Only_TemplateProps,
                        window.UI_Preview_Only_translate
                    );
                } catch (err: any) {
                    console.error('组件渲染错误:', err);
                    return React.createElement('div', { style: { color: 'red' } }, '组件渲染错误: ' + err.message);
                }
            };
            return WrappedComponent;
        } catch (err: any) {
            console.error('创建组件时出错:', err);
            return () => React.createElement('div', { style: { color: 'red' } }, '创建组件失败: ' + err.message);
        }
    };

    // 主要的副作用：当代码变化时，重新创建和渲染组件
    useEffect(() => {
        try {
            initializeGlobalObjects(); // 初始化全局对象
            const { transformedCode, propsType: extractedPropsType, componentName: extractedComponentName } = transformCode(code);
            console.log('提取的组件名称:', extractedComponentName);
            console.log('提取的 propsType:', extractedPropsType);
            setPropsType(extractedPropsType);
            setComponentName(extractedComponentName);
            const ComponentFunction = createComponent(transformedCode, extractedPropsType, extractedComponentName);
            if (ComponentFunction && typeof ComponentFunction === 'function') {
                console.log('成功创建组件函数');
                setComponent(ComponentFunction);
                setError(null);
            } else {
                throw new Error('无效的组件函数');
            }
            setKey(prevKey => prevKey + 1);
        } catch (err: any) {
            console.error('转换代码时出错:', err);
            setComponent(null);
            setError(err.message);
        }
    }, [code]);

    // 渲染内容
    const renderContent = () => {
        if (error) {
            return <div style={{ color: 'red' }}>{translate('错误')}: {error}</div>;
        }
        if (Component && typeof Component === 'function') {
            console.log('正在尝试渲染组件');
            const propKey = `UI_Preview_Only_${propsType}`;
            const props = (window as any)[propKey] || {};
            console.log('渲染时使用的props:', props);
            return (
                <ErrorBoundary
                    key={key}
                    fallback={<div style={{ color: 'red' }}>{translate('渲染错误')}</div>}
                >
                    <Component {...props} React={React} />
                </ErrorBoundary>
            );
        }
        return <div>{translate('预览将在此处显示...')}</div>;
    };

    return renderContent();
};

export default UIPreviewComponent;
