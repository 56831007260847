import { IItemSpecDto } from "@/interfaces/Responses/Responses";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { DeviceType } from "@/Templates/enums/templateEnums";
import { IItemViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { Button, Carousel, Col, Flex, Form, Image, Input, Row, Select, Slider, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React, { useContext, useState } from "react";
import 'react-quill/dist/quill.snow.css';
import { v4 as uuidV4 } from "uuid";
import './ItemDetail.css';

export interface ItemDetailProps {
    item: IItemViewModel;
    itemSpecs: IItemSpecDto[];
    isPreview?: boolean;
}
const ItemDetail: React.FC<ItemDetailProps> = ({ item, itemSpecs }) => {
    const { deviceType } = useContext(GlobalContext);
    const { messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedItemSpec, setSelectedItemSpec] = useState<IItemSpecDto | null>(itemSpecs?.[0] || null);
    const [purchaseQuantity, setPurchaseQuantity] = useState(0);

    const handleSpecChange = (value: string) => {
        const spec = itemSpecs.find(spec => spec.id.toString() === value);
        setSelectedItemSpec(spec || null);
        setPurchaseQuantity(0);
    };

    if (isLoading) {
        return <LoadingComponent></LoadingComponent>
    }

    return <>
        <Row>
            <Col style={{ padding: '20px' }} span={deviceType === DeviceType.Mobile ? 24 : 12}>
                {/* carosuel of images */}
                <Carousel>
                    {item?.itemConverImagePaths.map((imagePath) => (
                        <Image
                            key={imagePath}
                            src={imagePath}
                            width={'100%'}>
                        </Image>
                    ))}
                </Carousel>
            </Col>
            {/* right form */}
            <Col style={{ padding: '20px' }} span={deviceType === DeviceType.Mobile ? 24 : 12}>
                {itemSpecs &&
                    <>
                        <Title level={2}>{item?.name}</Title>
                        <Form layout={"horizontal"}>
                            <Form.Item label={translate('Spec')}>
                                <Select defaultValue={itemSpecs.length > 0 ? itemSpecs[0].id.toString() : undefined} onChange={handleSpecChange}>
                                    {itemSpecs.map(item => (
                                        <Select.Option key={uuidV4()} value={item.id.toString()}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {selectedItemSpec && (
                                <>
                                    <Form.Item label={translate('Note')}>
                                        <Input disabled value={selectedItemSpec.note} />
                                    </Form.Item>
                                    <Form.Item label={translate('Length')}>
                                        <Input disabled value={selectedItemSpec.length} />
                                    </Form.Item>
                                    <Form.Item label={translate('Weight')}>
                                        <Input disabled value={selectedItemSpec.weight} />
                                    </Form.Item>
                                    <Form.Item label={translate('Height')}>
                                        <Input disabled value={selectedItemSpec.height} />
                                    </Form.Item>
                                    <Form.Item label={translate('Weight')}>
                                        <Input disabled value={selectedItemSpec.weight} />
                                    </Form.Item>
                                    <Form.Item label={translate('Sell Price')}>
                                        <Input disabled value={selectedItemSpec.sellPrice} />
                                    </Form.Item>
                                    <Form.Item label={translate('Fixed Price')}>
                                        <Input disabled value={selectedItemSpec.fixedPrice} />
                                    </Form.Item>
                                    {
                                        selectedItemSpec.isManageStockAmount &&
                                        <Form.Item label={translate('Remaining amount')}>
                                            <Input disabled value={selectedItemSpec.stockAmount} />
                                        </Form.Item>
                                    }
                                    {
                                        selectedItemSpec?.stockAmount === 0 ? (
                                            <Typography.Text type="danger">{translate('Sell Out')}</Typography.Text>
                                        ) : (
                                            <Form.Item label={translate('Buy amount')}>
                                                <Input type="number" min={1} max={selectedItemSpec.isManageStockAmount ? selectedItemSpec?.stockAmount : 100} value={purchaseQuantity} onChange={e => setPurchaseQuantity(Number(e.target.value))} />
                                                <Slider min={0} max={selectedItemSpec.isManageStockAmount ? selectedItemSpec?.stockAmount : 100} value={purchaseQuantity} onChange={value => setPurchaseQuantity(value)} />
                                            </Form.Item>
                                        )
                                    }
                                    <Form.Item>
                                        <Flex justify="center">
                                            <Button type="primary" disabled={purchaseQuantity <= 0}>
                                                {translate('Add to shopping cart')}
                                            </Button>
                                        </Flex>
                                    </Form.Item>
                                </>
                            )}
                        </Form>
                    </>
                }
            </Col>
            <Col span={24}>
                <div className='quill'>
                    <div className='ql-container'>
                        <div
                            className="ql-editor"
                            style={{ maxWidth: '100%', overflowX: 'auto' }}
                            dangerouslySetInnerHTML={{ __html: item?.description || "" }}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    </>
}

export default ItemDetail;
