import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { Card, Col, Flex, Row, Statistic } from 'antd';
import Title from 'antd/es/typography/Title';
import React, { useContext, useEffect } from 'react';
import RecentOrdersChart from './Components/RecentOrdersChart';
import { DataAnalysisPageContext, DataAnalysisPageProvider } from './Contexts/DataAnalysisPageContext';


const DataStatistics: React.FC = () => {
    return (
        <Row gutter={16}>
            <Col span={8}>
                <Card>
                    <Statistic title="Total Sales" value={10} />
                </Card>
            </Col>
            <Col span={8}>
                <Card>
                    <Statistic title="Total Revenue" value={20} precision={2} />
                </Card>
            </Col>
            <Col span={8}>
                <Card>
                    <Statistic title="Total Customers" value={30} />
                </Card>
            </Col>
        </Row>
    );
};

const DataAnalysisContent: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const { abortController } = useContext(DataAnalysisPageContext);
    useEffect(() => {
        return () => {
            abortController.current.abort();
        };
    }, []);

    return (
        <div style={{ padding: '20px' }}>
            <Flex justify='center'>
                <Title level={3}>{translate('Data Analysis')}</Title>
            </Flex>
            <DataStatistics />
            <RecentOrdersChart />
        </div>
    );
};

const DataAnalysisPage: React.FC = () => {


    return (
        <DataAnalysisPageProvider>
            <DataAnalysisContent />
        </DataAnalysisPageProvider>
    );
};

export default DataAnalysisPage;