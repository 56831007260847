import { INavBarOption, IUpsertNavBarSettingRequest } from "@/interfaces/Responses/Responses";
import { useGetNavBarOptionItemSearchModelApi, useGetNavBarOptionsApi, useGetNavBarSettingsApi, useUpsertNavBarSettingApi } from "@/lib/api/frontendsettings";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import LoadingComponent from "@/Templates/components/LoadingCompoent";
import { NavBarClickType } from "@/Templates/enums/templateEnums";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import { ReactNode, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { PortalSettingPageContext } from "../Contexts/PortalSettingPageContext";
import EditNavBarOptions from "./EditNavBarOptions";

const EditNavBarSetting = () => {
    const { generateHelper, merchantId, messageApi } = useContext(GlobalContext);
    const {
        navBarSetting,
        setNavBarSetting,
        navBarOptions,
        setNavBarOptions,
        setSearchModel
    } = useContext(PortalSettingPageContext);
    const { translate } = useContext(TranslationContext);
    const { abortController } = useContext(PortalSettingPageContext);
    const [isInitialized, setIsInitialized] = useState(false);

    // 獲取導航欄設置
    const { data: navBarSettingData, isLoading: isNavBarSettingLoading, refetch: refetchNavBarSetting } = useQuery(
        ['navBarSettings', merchantId],
        () => useGetNavBarSettingsApi(abortController.current.signal),
        {
            enabled: !!merchantId,
            onSuccess: (data) => {
                if (data.isSuccess && data.result) {
                    setNavBarSetting(data.result);
                }
            }
        }
    );

    // 獲取導航欄選項
    const { data: navBarOptionsData, isLoading: isNavBarOptionsLoading, refetch: refetchNavBarOptions } = useQuery(
        ['navBarOptions', navBarSettingData],
        async () => {
            if (!navBarSettingData?.result?.navBarOptionIds) return [];
            const finalOptions: INavBarOption[] = [];

            const getNavBarOptionsRecursive = async (ids: BigInt[]): Promise<void> => {
                if (abortController.current.signal.aborted) {
                    throw new Error('Operation cancelled');
                }

                try {
                    const options = await useGetNavBarOptionsApi(ids, abortController.current.signal);
                    if (options.isSuccess && options.result) {
                        finalOptions.push(...options.result);
                        await Promise.all(options.result.map(async (o) => {
                            if (o.childrenIds.length > 0) {
                                await getNavBarOptionsRecursive(o.childrenIds);
                            }
                        }));
                    }
                } catch (error) {
                    if (error instanceof Error && error.name === 'AbortError') {
                        console.log('Fetch aborted');
                        throw error; // Re-throw to be caught by React Query
                    }
                    throw error; // Re-throw other errors
                }
            };

            try {
                await getNavBarOptionsRecursive(navBarSettingData.result.navBarOptionIds);
                return finalOptions;
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    return []; // Return empty array if aborted
                }
                throw error; // Re-throw other errors
            }
        },
        {
            enabled: !!navBarSettingData?.result?.navBarOptionIds,
            onSuccess: (data) => {
                if (data) {
                    setNavBarOptions(data);
                    setIsInitialized(true);
                }
            },
            onError: (error) => {
                if (error instanceof Error && error.name !== 'AbortError') {
                    console.error('Error fetching nav bar options:', error);
                    messageApi.error(translate('Failed to fetch navigation bar options'));
                }
            }
        }
    );

    // 獲取搜索模型
    const { isLoading: isSearchModelLoading } = useQuery(
        'navBarOptionItemSearchModel',
        async () => await useGetNavBarOptionItemSearchModelApi(abortController.current.signal),
        {
            onSuccess: (data) => {
                if (data.isSuccess && data.result) {
                    setSearchModel(data.result);
                }
            }
        }
    );

    const { mutate: upsertNavBarOptionMutate, isLoading: upsertNavBarLoading } = useMutation(
        async (request: IUpsertNavBarSettingRequest) => await useUpsertNavBarSettingApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    refetchNavBarSetting();
                    refetchNavBarOptions();
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const AddNewOption = () => {
        const newOption: INavBarOption = {
            id: generateHelper.getSnowflakeIdBigInt(),
            merchantId: merchantId!,
            name: "New",
            type: NavBarClickType.RedirectPage,
            extraInfo: "/",
            isDeleted: false,
            orderIndex: navBarOptions.length + 1,
            childrenIds: [],
        };
        setNavBarSetting(prev => ({
            ...prev!,
            navBarOptionIds: [...(prev?.navBarOptionIds || []), newOption.id]
        }));
        setNavBarOptions(prev => [...prev, newOption]);
    };

    const SaveChanges = () => {
        if (!navBarSetting) return;

        const updatedNavBarSetting = {
            ...navBarSetting,
            navBarOptionIds: navBarSetting.navBarOptionIds.filter(id =>
                navBarOptions.some(option => option.id.toString() === id.toString())
            )
        };

        const updatedNavBarOptions = navBarOptions.map(option => ({
            ...option,
            childrenIds: option.childrenIds.filter(childId =>
                navBarOptions.some(o => o.id.toString() === childId.toString())
            )
        }));

        const request: IUpsertNavBarSettingRequest = {
            navBarSetting: updatedNavBarSetting,
            options: updatedNavBarOptions
        };

        upsertNavBarOptionMutate(request);
    };

    const rootOptions = (ids?: BigInt[]): ReactNode => {
        if (!ids || ids.length === 0) return null;
        const options = navBarOptions.filter(r => ids.some(id => id.toString() === r.id.toString()));
        options.sort((a, b) => a.orderIndex - b.orderIndex);
        return options.map((option) => (
            <EditNavBarOptions
                title={`[${translate('Layer')}${1}] ${option.name}`}
                layer={1}
                layerCount={options.length}
                merchantId={merchantId!}
                style={{ marginBottom: 10 }}
                key={option.id.toString()}
                selectedOption={option}
            />
        ));
    };

    const isLoading = isNavBarSettingLoading || isNavBarOptionsLoading || isSearchModelLoading || !isInitialized;

    if (isLoading) {
        return <LoadingComponent />;
    }

    return (
        <>
            {rootOptions(navBarSetting?.navBarOptionIds)}
            <Flex justify="center" style={{ margin: '10px' }}>
                <Button type="dashed" block icon={<PlusOutlined />} onClick={AddNewOption}>
                    {translate('Add')} {translate('Option')}
                </Button>
            </Flex>
            <Flex justify="center">
                <Button onClick={SaveChanges} loading={upsertNavBarLoading}>
                    {translate('Save')}
                </Button>
            </Flex>
        </>
    );
};

export default EditNavBarSetting;