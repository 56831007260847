import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import Popover from "antd/es/popover";
import { useContext } from "react";

const MerchantInfomationPopover = () => {
    const { i18nLanguage, translate } = useContext(TranslationContext);
    const contentZhTw = (
        <div>
            <p>
                一個帳號最多可以同時擁有（所有者） 5 個商戶，但不包含身為輔佐權限的商戶。
            </p>
            <p>
                (商戶：商戶是指一個獨立的網站，可以有自己的商品、訂單、會員等資料。)
            </p>
        </div>
    );
    const contentEn = (
        <div>
            <p>
                An account can have up to 5 merchants at the same time, excluding merchants with auxiliary permissions.
            </p>
            <p>
                (Merchant: A merchant refers to an independent website that can have its own product, order, member, etc.)
            </p>
        </div>
    );
    return (
        <Popover
            trigger="hover"
            content={i18nLanguage === 'zh_TW' ? contentZhTw : contentEn}
        >
            <QuestionCircleTwoTone />
        </Popover>
    )
}

export default MerchantInfomationPopover;