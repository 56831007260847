import { IAlterItemIsOnShelfRequest, IDeleteItemRequest } from '@/interfaces/Requests/Requests';
import { useAlterItemIsOnShelfApi, useDeleteItemApi, useGetItemsByMerchantApi } from '@/lib/api/items';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { IItemViewModel } from '@/Templates/interfaces/templatesInterfaces';
import type { ProColumns, ProFormInstance } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import { Button, Empty, Image, Modal, Select, Space, Switch, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const ItemManagePage: React.FC = () => {
    const { deviceType, merchantId, messageApi } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const formRef = useRef<ProFormInstance>();
    const abortController = useRef(new AbortController());

    const { mutate: alterOnShelfMutate, isLoading: alterOnShelfMutateIsLoading } = useMutation(
        async (request: IAlterItemIsOnShelfRequest) => await useAlterItemIsOnShelfApi(request),
        {
            onSuccess: (response, request) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    // alter the switch UI status in the table
                    const data = getItemsByMerchantMutation.data;
                    if (data) {
                        const item = data.result?.data.find((item) => item.id === request.itemId);
                        if (item) {
                            item.isOnShelf = request.isOnShelf;
                        }
                    }
                }
                else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const deleteItemMutation = useMutation(
        async (request: IDeleteItemRequest) => await useDeleteItemApi(request),
        {
            onSuccess: (response) => {
                if (response.isSuccess) {
                    messageApi.success(translate('Operation success'));
                    formRef.current?.submit();
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const getItemsByMerchantMutation = useMutation(
        async (params: any) => {
            const response = await useGetItemsByMerchantApi({
                merchantId: merchantId || BigInt(0),
                page: params.current || 1,
                pageSize: params.pageSize || 10,
                fuzzingName: params.fuzzingName,
                categoryIds: params.categoryIds,
                extraItemIds: params.extraItemIds,
                extraItemTagIds: params.extraItemTagIds,
                extraOperationInclude: params.extraOperationInclude,
                isOrderByDesc: params.isOrderByDesc,
                isOnShelf: params.isOnShelf,
            }, abortController.current.signal);
            return response;
        },
        {
            onSuccess: (response, variables, context) => {
                if (!response.isSuccess) {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            }
        }
    );

    const handleDelete = (itemId: BigInt) => {
        Modal.confirm({
            title: translate('Are you sure to delete') + '?',
            okText: translate('Yes'),
            onOk: () => deleteItemMutation.mutate({ itemId }),
            cancelText: translate('No'),
        });
    };

    const handleIsOnShelfChange = (itemId: BigInt, checked: boolean) => {
        alterOnShelfMutate({ itemId, isOnShelf: checked });
    };

    const columns: ProColumns<IItemViewModel>[] = [
        {
            title: translate('Name'),
            dataIndex: "name",
            render: (_, record) => (
                <Button type="link" onClick={() => navigate(`/editItem?itemId=${record.id}&previewOnly=${record.isOnShelf}`)}>{record.name}</Button>
            ),
            fixed: 'left',
            search: {
                transform: (value) => ({ fuzzingName: value }),
            },
        },
        {
            title: translate('Image'),
            dataIndex: "itemConverImagePaths",
            hideInSearch: true,
            render: (_, record) => (
                <Space>
                    {
                        record.itemConverImagePaths?.length === 0 &&
                        <Empty></Empty>
                    }
                    {record.itemConverImagePaths?.map((url: string) => (
                        <Image
                            key={url}
                            width={80}
                            height={80}
                            src={url}
                            preview={{
                                mask: translate("Preview"),
                            }}
                        />
                    ))}
                </Space>
            )
        },
        {
            title: translate('IsOnShelf'),
            dataIndex: "isOnShelf",
            renderFormItem(schema, config, form, action) {
                return (
                    <Select allowClear>
                        <Select.Option value={true}>{translate('Yes')}</Select.Option>
                        <Select.Option value={false}>{translate('No')}</Select.Option>
                    </Select>
                );
            },
            render: (_, record) => (
                <Switch
                    loading={alterOnShelfMutateIsLoading}
                    checked={record.isOnShelf}
                    onChange={(checked) => handleIsOnShelfChange(record.id, checked)}
                />
            ),
        },
        {
            title: translate('Operation'),
            valueType: 'option',
            render: (_, record) => [
                <Tooltip key="edit" title={record.isOnShelf ? translate("Need to be off shelf") : ""}>
                    <Button
                        type="link"
                        disabled={record.isOnShelf}
                        onClick={() => navigate(`/editItem?itemId=${record.id}`)}
                    >
                        {translate('Edit')}
                    </Button>
                </Tooltip>,
                <Tooltip key="delete" title={record.isOnShelf ? translate("Need to be off shelf") : ""}>
                    <Button
                        danger
                        loading={deleteItemMutation.isLoading}
                        type="link"
                        disabled={record.isOnShelf}
                        onClick={() => handleDelete(record.id)}
                    >
                        {translate('Delete')}
                    </Button>
                </Tooltip>
            ],
        }
    ];

    useEffect(() => {
        return () => {
            abortController.current.abort();
        }
    }, []);

    return (
        <ProTable<IItemViewModel>
            columns={columns}
            formRef={formRef}
            ghost={true}
            cardBordered
            request={async (params, sorter, filter) => {
                const { result, isSuccess, message } = await getItemsByMerchantMutation.mutateAsync({
                    ...params,
                    fuzzingName: params.fuzzingName,
                });
                if (!isSuccess) {
                    messageApi.error(translate(message || 'Operation failed'));
                    return {
                        data: [],
                        success: false,
                    };
                }
                return {
                    data: result?.data || [],
                    success: true,
                    total: result?.totalCount || 0,
                };
            }}
            editable={{
                type: 'multiple',
            }}
            rowKey="id"
            search={{
                labelWidth: 'auto',
                span: 12,
            }}
            toolBarRender={() => [
                <Button
                    key="add"
                    type="primary"
                    onClick={() => navigate('/addItem')}
                >
                    {translate('Add Item')}
                </Button>
            ]}
            pagination={{
                pageSize: 10,
                showQuickJumper: true,
            }}
            dateFormatter="string"
        />
    );
};

export default ItemManagePage;