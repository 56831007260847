import { ISwitchBackOfficeMerhcnatRequest } from "@/interfaces/Requests/Requests";
import { IMerchant } from "@/interfaces/Responses/Responses";
import { useSwitchBackOfficeMerhcnatApi } from "@/lib/api/portals";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Card, Col, Flex, Modal, Row, Select, Tag, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from "react-query";
import MerchantInfomationPopover from "./MerchantInfomationPopover";
import MerchantUpgradeOrder, { IMerchantUpgradeOrderRef } from "./MerchantUpgradeOrder";

export interface IMerchantInfosProp {
    merchantId: BigInt | undefined;
    merchants: IMerchant[] | undefined;
}

const MerchantInfos: React.FC<IMerchantInfosProp> = ({ merchantId, merchants }) => {
    const { Title } = Typography;
    const { setMerchantIdAggregate, messageApi, portalLink, tryGetPortalLink, setIsPageLoading } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const merchantUpgradeOrderRef = useRef<IMerchantUpgradeOrderRef>();
    const [visibleForUpgrade, setVisibleForUpgrade] = useState(false);
    const safeMerchants = merchants || [];
    const [selectedMerchantId, setSelectedMerchantId] = useState<string | undefined>(
        merchantId?.toString() || (safeMerchants.length > 0 ? safeMerchants[0].id.toString() : undefined)
    );
    const handleChange = (value: string) => {
        setSelectedMerchantId(value);
    };
    const { mutate, isLoading } = useMutation(async (request: ISwitchBackOfficeMerhcnatRequest) => {
        setIsPageLoading(true);
        return await useSwitchBackOfficeMerhcnatApi(request);
    },
        {
            onSuccess: (response, reqeust) => {
                if (response.isSuccess) {
                    localStorage.setItem("JWTToken", response.result!);
                    setMerchantIdAggregate(reqeust.merchantId);
                } else {
                    messageApi.error(translate(response.message || 'Operation failed'));
                }
            },
            onSettled: () => {
                setIsPageLoading(false);
            }
        });
    const selectedMerchant = safeMerchants.find(m => m.id.toString() === selectedMerchantId);

    useEffect(() => {
        if (merchantId !== undefined && selectedMerchantId !== merchantId.toString()) {
            setSelectedMerchantId(merchantId.toString());
        }
    }, [merchantId]);

    useEffect(() => {
        if (selectedMerchantId) {
            mutate({ merchantId: BigInt(selectedMerchantId) });
            tryGetPortalLink(selectedMerchantId);
        }
    }, [selectedMerchantId]);

    const renderExpireTime = (expireTime: string) => {
        const isExpired = new Date(expireTime) < new Date();
        return (
            <span>
                {isExpired ? (
                    <>
                        <span style={{ color: 'red' }}>{expireTime}</span>
                        <Tag color="red">{translate('Expired')}</Tag>
                    </>
                ) : (
                    <span>{expireTime}</span>
                )}
            </span>
        );
    };

    return (
        <>
            <Row style={{ margin: '20px' }}>
                {safeMerchants.length > 0 && (
                    <Col span={24}>
                        <Flex justify="center" align="center">
                            <Title level={3}>{translate('Merchants')}</Title>
                        </Flex>
                        <Flex justify="center" align="center">
                            <MerchantInfomationPopover />
                            <Select
                                style={{ width: 200 }}
                                value={selectedMerchantId}
                                onChange={handleChange}
                                placeholder={translate('Select') + ' ' + translate('Merchant')}
                            >
                                {safeMerchants.map(merchant => (
                                    <Select.Option key={merchant.id.toString()} value={merchant.id.toString()}>
                                        {merchant.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Flex>
                    </Col>
                )}
                <Col style={{ marginTop: '30px' }} span={24}>
                    <Flex justify="center">
                        {selectedMerchant && (
                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Card>
                                    <p>{translate('Merchant ID')}: {selectedMerchant.id.toString()}</p>
                                    <p>
                                        {portalLink &&
                                            <a href={portalLink} target="_blank" rel="noopener noreferrer">
                                                {translate('Visit portal website')}
                                            </a>
                                        }
                                    </p>
                                    <p>
                                        {translate('Country')}: {translate(`Country.${selectedMerchant.country}`)}
                                    </p>
                                    <p>
                                        {translate('Website')}{translate('Level')}: {translate(`MerchantWebsiteUpgradeLevel.${selectedMerchant.merchantWebsiteUpgradeLevel}`)}
                                    </p>
                                    <p>
                                        {translate('Expire Time')}: {renderExpireTime(selectedMerchant.upgradeExpiredTime)}
                                    </p>
                                    <p>{translate('Create Date')}: {selectedMerchant.createDate}</p>
                                    <p>{translate('Banned')}: {selectedMerchant.isBanned ? translate('Yes') : translate('No')}</p>
                                    <Flex justify="center">
                                        <Button onClick={() => setVisibleForUpgrade(true)} disabled={selectedMerchant.isBanned}>
                                            {translate('Subscribe')}
                                        </Button>
                                    </Flex>
                                    <Modal
                                        open={visibleForUpgrade}
                                        afterOpenChange={(open) => {
                                            if (open) {
                                                merchantUpgradeOrderRef.current?.refetch();
                                            }
                                        }}
                                        onCancel={() => {
                                            setVisibleForUpgrade(false);
                                            if (merchantUpgradeOrderRef.current) {
                                                merchantUpgradeOrderRef.current.resetSelectedLevel();
                                            }
                                        }}
                                        footer={null}
                                    >
                                        <MerchantUpgradeOrder
                                            merchant={selectedMerchant}
                                            ref={merchantUpgradeOrderRef}
                                        />
                                    </Modal>
                                </Card>
                            </Col>
                        )}
                    </Flex>
                </Col>
            </Row>
        </>
    );
}

export default MerchantInfos;
