import { useGetAccessibleMerchantsApi } from "@/lib/api/merchants";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ApplyNewMerchant from "./Components/ApplyNewMerchant";
import MerchantInfos from "./Components/MerchantInfos";

const HomePage = () => {
  const { messageApi, hasMerchant, setHasMerchant, merchantId, setMerchantIdAggregate } = useContext(GlobalContext);
  const { translate } = useContext(TranslationContext);
  const navigate = useNavigate();

  const { data: accessibleMerchantsData, refetch: refetchOwnMerchants } = useQuery(
    'ownMerchants',
    () => useGetAccessibleMerchantsApi(undefined),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    if (!accessibleMerchantsData || !accessibleMerchantsData.isSuccess) {
      setHasMerchant(false);
      return;
    }

    const merchants = accessibleMerchantsData.result;

    if (!merchants || merchants.length === 0) {
      setHasMerchant(false);
      return;
    }

    setHasMerchant(true);

    const currentMerchantExists = merchants.some(merchant => merchant.id == merchantId);

    if (!currentMerchantExists || merchantId === undefined) {
      const firstMerchant = merchants[0];
      if (firstMerchant && firstMerchant.id) {
        messageApi.warning(translate("auto switch to first own merchant"));
        setMerchantIdAggregate(firstMerchant.id);
      } else {
        console.error("No valid merchant found in the accessible merchants list");
      }
    }
  }, [accessibleMerchantsData, merchantId, setHasMerchant, setMerchantIdAggregate, messageApi, translate]);


  return (
    <>
      {hasMerchant && accessibleMerchantsData?.result && (
        <MerchantInfos merchantId={merchantId} merchants={accessibleMerchantsData.result} />
      )}
      <ApplyNewMerchant merchants={accessibleMerchantsData?.result ? accessibleMerchantsData?.result : []} />
    </>
  );
};

export default HomePage;