import { StyleProvider } from "@ant-design/cssinjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { message } from "antd";
import "antd/dist/reset.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import JSONBig from 'json-bigint';
import React, { useCallback } from "react";
import ReactDOM from "react-dom/client";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, useNavigate } from "react-router-dom";
import "./index.css";
import { GlobalProvider } from "./lib/contexts/GlobalContext.js";
import { TranslationProvider } from "./lib/contexts/TranslationContext.js";
import App from "./pages/AppPages/App.js";

// 定义 JSONBig 实例
const jsonBig = JSONBig({ storeAsString: true });
// 全局覆盖 JSON 的 stringify 和 parse 方法
window.JSON.stringify = jsonBig.stringify.bind(jsonBig);
window.JSON.parse = jsonBig.parse.bind(jsonBig);

// 自定義 hook 來處理錯誤和導航
const useErrorHandler = (messageApi: any) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('Global');

  const handleError = useCallback((error: unknown) => {
    if (error instanceof Error) {
      if (error.message === 'Unauthorized') {
        navigate('/login');
      } else if (error.message === 'Forbidden') {
        messageApi.error(t('Forbidden'));
        navigate('/');
      }
      else if (error.message === 'Request was cancelled') {
        return;
      }
      else {
        messageApi.error(t(error.message));
      }
    }
  }, [navigate, messageApi]);

  return handleError;
};

// Root 組件
const Root: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage({
    duration: 2,
    maxCount: 2,
  });

  const handleError = useErrorHandler(messageApi);

  // 創建一個新的 QueryClient
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 0, //disable cache
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        retry: false,
        onError: handleError,
      },
      mutations: {
        retry: false,
        onError: handleError,
      }
    },
  });

  return (
    <GoogleOAuthProvider clientId={`${process.env.GoogleClientId}`}>
      <StyleProvider>
        <QueryClientProvider client={queryClient}>
          <GlobalProvider messageApi={messageApi}>
            <TranslationProvider>
              {contextHolder}
              <App />
            </TranslationProvider>
          </GlobalProvider>
        </QueryClientProvider>
      </StyleProvider>
    </GoogleOAuthProvider>
  );
};

// 主應用包裝器
const AppWrapper: React.FC = () => (
  <BrowserRouter>
    <Root />
  </BrowserRouter>
);

ReactDOM.createRoot(document.getElementById("root")!).render(<AppWrapper />);

// 未使用UseQuery及其它的錯誤處理
window.addEventListener('unhandledrejection', (event) => {
  if (event.reason.message === 'Unauthorized') {
    // 這裡我們不能直接使用 navigate，因為這是在 React 上下文之外
    // 我們可以分發一個自定義事件，然後在 React 組件中監聽這個事件
    window.dispatchEvent(new CustomEvent('unauthorizedError'));
  }
});