import { MerchantWebsiteUpgradeLevel } from "@/enums/Enums";
import { IAlterPortalStyleRequest, IBeAssistantRequest, IInviteAssistanceRequest, IRemoveAssistanceRequest } from "@/interfaces/Requests/Merchant";
import { IAlterMerchantPortalSettingRequest, ICreateMerchantRequest, ICreateMerchantUpgradeMerchantWebsiteLevelOrderRequest, ICreatePaymentOrderRequest, ILoginRequest, IMerchantApplyRequest } from "@/interfaces/Requests/Requests";
import { IMerchant, IMerchantAssistant, IMerchantAssistantViewModel, IMerchantPortalOptionSettingDto, IOperationResult, IOperationResultT } from "@/interfaces/Responses/Responses";
import { IMerchantApply } from "@/interfaces/Responses/SystemManager";
import { IThirdPartyPaymentCreateRespnse } from "@/interfaces/Responses/ThirdPartyPayment";
import useAPI from "../customHooks/useAPI";

export const useIsMerchantApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/Merchant/IsMerchant`, { signal });

export const useGetMerchantApplyByUserIdApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchantApply>>(`${process.env.BASE_API_URL}/api/Merchant/GetMerchantApplyByUserId`, { signal });

export const useMerchantApplyApi = (params: IMerchantApplyRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Merchant/MerchantApply`, {
        method: 'POST',
        body: params,
        signal
    });

export const useCreateMerchantApi = (params: ICreateMerchantRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Merchant/CreateMerchant`, {
        method: 'POST',
        body: params,
        signal
    });

export const useMerchantLoginApi = (params: ILoginRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<string>>(`${process.env.BASE_API_URL}/api/Merchant/Login`, {
        method: 'POST',
        body: params,
        signal
    });

export const useGetAccessibleMerchantsApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchant[]>>(`${process.env.BASE_API_URL}/api/Merchant/GetAccessibleMerchants`, { signal });

export const useGetOwnMerchantsApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchant[]>>(`${process.env.BASE_API_URL}/api/Merchant/GetOwnMerchants`, { signal });

export const useGetCanUpgradeMerchantWebsiteLevelsApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<MerchantWebsiteUpgradeLevel[]>>(`${process.env.BASE_API_URL}/api/Merchant/GetCanUpgradeMerchantWebsiteLevels`, { signal });

export const useCreateTutorialUpgradeMerchantWebsiteLevelOrderApi = (params: ICreateMerchantUpgradeMerchantWebsiteLevelOrderRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/Merchant/CreateTutorialUpgradeMerchantWebsiteLevelOrder`, {
        method: 'POST',
        body: params,
        signal
    });

export const useCreateUpgradeMerchantWebsiteLevelOrderApi = (params: ICreateMerchantUpgradeMerchantWebsiteLevelOrderRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<BigInt>>(`${process.env.BASE_API_URL}/api/Merchant/CreateUpgradeMerchantWebsiteLevelOrder`, {
        method: 'POST',
        body: params,
        signal
    });

export const useCreatePaymentOrderApi = (params: ICreatePaymentOrderRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<IThirdPartyPaymentCreateRespnse>>(`${process.env.BASE_API_URL}/api/ThirdPartyPayment/CreateOrder`, {
        method: 'POST',
        body: params,
        signal
    });

export const useGetMerchantPortalSettingApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchantPortalOptionSettingDto>>(`${process.env.BASE_API_URL}/api/Merchant/GetMerchantPortalSetting`, { signal });

export const useAlterMerchantPortalSettingApi = (params: IAlterMerchantPortalSettingRequest, signal?: AbortSignal) =>
    useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/Merchant/AlterMerchantPortalSetting`, {
        method: 'POST',
        body: params,
        signal
    });

export const useGetAssistanceInvitationsApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchantAssistantViewModel[]>>(`${process.env.BASE_API_URL}/api/Merchant/GetAssistanceInvitations`, { signal });

export const useGetAssistancesApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchantAssistant[]>>(`${process.env.BASE_API_URL}/api/Merchant/GetAssistances`, { signal });

export const useInviteAssistanceApi = (request: IInviteAssistanceRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Merchant/InviteAssistance`, {
        method: 'POST',
        body: request,
        signal
    });

export const useRemoveAssistanceApi = (request: IRemoveAssistanceRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Merchant/RemoveAssistance`, {
        method: 'POST',
        body: request,
        signal
    });

export const useAcceptToBeAssistantApi = (request: IBeAssistantRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Merchant/AcceptToBeAssistant`, {
        method: 'POST',
        body: request,
        signal
    });

export const useRejectToBeAssistantApi = (request: IBeAssistantRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Merchant/RejectToBeAssistant`, {
        method: 'POST',
        body: request,
        signal
    });

export const useGetUserInfoEventCountApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<number>>(`${process.env.BASE_API_URL}/api/Merchant/GetUserInfoEventCount`, { signal });

export const useAlterMerchantIconApi = (params: FormData, signal?: AbortSignal) =>
    useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/Merchant/AlterMerchantIcon`, {
        method: 'POST',
        body: params,
        contentType: 'multipart/form-data',
        signal
    });

export const useIsEnableToUnSubscribeApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<boolean>>(`${process.env.BASE_API_URL}/api/Merchant/IsEnableToUnSubscribe`, { signal });

export const useAlterPortalStyleApi = (params: IAlterPortalStyleRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/Merchant/AlterPortalStyle`, {
        method: 'POST',
        body: params,
        signal
    });