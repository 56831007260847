import { ImageUseageType } from '@/enums/Enums';
import { useUploadImageApi } from '@/lib/api/images';
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { DeviceType } from '@/Templates/enums/templateEnums';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, InputNumber, Select, Upload } from 'antd';
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';

interface StyleConfigSelectorProps {
    selectedStyle: number;
    styleConfig: Record<string, string>;
    configFields: Array<any>;
    onStyleChange: (value: number) => void;
    onConfigChange: (key: string, value: string) => void;
    onSave: () => void;
    styleOptions: Array<{ value: number; label: string }>;
}

const StyleConfigSelector: React.FC<StyleConfigSelectorProps> = ({
    selectedStyle,
    styleConfig,
    configFields,
    onStyleChange,
    onConfigChange,
    onSave,
    styleOptions,
}) => {
    const { deviceType, messageApi, merchantId } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [fileList, setFileList] = useState<Record<string, any[]>>({});

    const uploadImageMutation = useMutation(
        async ({ file, fieldKey }: { file: File; fieldKey: string }) => {
            const formData = new FormData();
            formData.append('File', file);
            formData.append('UseageType', ImageUseageType.ForPreview);
            formData.append('ReferenceId', merchantId ? merchantId.toString() : '');
            return useUploadImageApi(formData);
        },
        {
            onSuccess: (response, { file, fieldKey }) => {
                if (response.isSuccess && response.result) {
                    onConfigChange(fieldKey, response.result);
                    setFileList((prev) => ({
                        ...prev,
                        [fieldKey]: [
                            {
                                uid: response.result,
                                name: file.name,
                                status: 'done',
                                url: response.result,
                            },
                        ],
                    }));
                } else {
                    messageApi.error(translate('Image upload failed'));
                }
            },
            onError: (error) => {
                console.error('Image upload error:', error);
                messageApi.error(translate('Image upload failed'));
            },
        }
    );

    const handleImageUpload = (file: File, fieldKey: string) => {
        uploadImageMutation.mutate({ file, fieldKey });
        return false;
    };

    const renderConfigField = (field: any) => {
        switch (field.type) {
            case 'color':
                return (
                    <Input
                        type="color"
                        value={styleConfig[field.key] || field.defaultValue}
                        onChange={(e) => onConfigChange(field.key, e.target.value)}
                    />
                );
            case 'select':
                return (
                    <Select
                        value={styleConfig[field.key] || field.defaultValue}
                        onChange={(value) => onConfigChange(field.key, value)}
                        options={field.options}
                    />
                );
            case 'number':
                return (
                    <InputNumber
                        value={styleConfig[field.key] || field.defaultValue}
                        onChange={(value) => onConfigChange(field.key, value?.toString() || '')}
                        min={field.min}
                        max={field.max}
                    />
                );
            case 'input':
                return (
                    <Input
                        value={styleConfig[field.key]}
                        onChange={(e) => onConfigChange(field.key, e.target.value)}
                    />
                );
            case 'upload':
                return (
                    <Upload
                        fileList={fileList[field.key] || []}
                        accept="image/*"
                        showUploadList={true}
                        maxCount={1}
                        onRemove={() => {
                            onConfigChange(field.key, '');
                            setFileList((prev) => ({
                                ...prev,
                                [field.key]: [],
                            }));
                        }}
                        beforeUpload={(file) => {
                            handleImageUpload(file, field.key);
                            return false;
                        }}
                    >
                        <Button icon={<UploadOutlined />} loading={uploadImageMutation.isLoading}>
                            {styleConfig[field.key] ? '更改图片' : '上传图片'}
                        </Button>
                    </Upload>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Button type="default" onClick={() => setDrawerVisible(true)}>
                {translate('Configure')}
            </Button>
            <Drawer
                title={translate('Style Configuration')}
                placement={deviceType === DeviceType.Mobile ? 'left' : 'right'}
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
                width={deviceType === DeviceType.Mobile ? '100%' : '30%'}
            >
                <Form layout="vertical">
                    <Form.Item label={translate('Style')}>
                        <Select value={selectedStyle} onChange={onStyleChange} options={styleOptions} />
                    </Form.Item>
                    {configFields.map((field) => (
                        <Form.Item key={field.key} label={translate(field.label)}>
                            {renderConfigField(field)}
                        </Form.Item>
                    ))}
                    <Form.Item>
                        <Button type="primary" onClick={onSave}>
                            {translate('Save')}
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default StyleConfigSelector;