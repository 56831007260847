import { INavBarOptionItemSearchModel } from "@/interfaces/Responses/Responses";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Collapse, CollapseProps, GetProp, UploadProps } from "antd";
import React, { useContext } from "react";
import { v4 as uuidv4 } from 'uuid';
import EditNavBarSetting from "./EditNavBarSetting";
import MarqueeImagesEditor from "./MarqueeImagesEditor";

export interface IFrontEndSettingProps {
}
export interface IFrontEndSettingRef {
    onRefresh: () => void;
}
const FrontEndSetting = React.forwardRef((props: IFrontEndSettingProps, ref: React.ForwardedRef<IFrontEndSettingRef | undefined>) => {
    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
    const { merchantId, messageApi, merchantPortalOptionSetting, getMerchantPortalOptionSetting } = useContext(GlobalContext);
    const { supportedLanguages, translate } = useContext(TranslationContext);
    const [searchModel, setSearchModel] = React.useState<INavBarOptionItemSearchModel>();

    const items: CollapseProps['items'] = [
        {
            key: 'navBarOptionsSetting',
            label: `${translate('NavBarOptionsSetting')}`,
            children: <>
                <EditNavBarSetting key={uuidv4()}></EditNavBarSetting>
            </>,
        },
        {
            key: 'marueeSetting',
            label: `${translate('Marquee Images')}`,
            children: <>
                <MarqueeImagesEditor></MarqueeImagesEditor>
            </>,
        }
    ];

    return (
        <>
            <Collapse items={items} >
            </Collapse>
        </>
    );
});

export default FrontEndSetting;