
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import React, { useContext, useEffect, useState } from 'react';
import { TemplateProps } from '../../../Templates/TemplateProps';

interface LandingPagePreviewProps extends TemplateProps {
    selectedStyle: number;
    onConfigFieldsChange: (fields: Array<{ key: string, label: string, type: string, defaultValue: string }>) => void;
}

const LandingPagePreview: React.FC<LandingPagePreviewProps> = ({ merchantId, selectedStyle, portalOptionSettingViewModel, portalStyleSettings, config, onConfigFieldsChange, messageApi }) => {
    const { translate } = useContext(TranslationContext);
    const [TemplateComponent, setTemplateComponent] = useState<React.ComponentType<TemplateProps> | null>(null);

    useEffect(() => {
        const loadTemplate = async () => {
            try {
                const module = await import(`@/Templates/LandingPage/Template_${selectedStyle}.tsx`);
                setTemplateComponent(() => module.default);
                if (module.getConfigFields) {
                    const fields = module.getConfigFields();
                    onConfigFieldsChange(fields);
                } else {
                    onConfigFieldsChange([]);
                }
            } catch (error) {
                console.error('加载模板失败:', error);
                setTemplateComponent(null);
                onConfigFieldsChange([]);
            }
        };

        loadTemplate();
    }, [selectedStyle]);


    if (!TemplateComponent) {
        return <div>{translate('Failed to load template')}</div>;
    }

    return (
        <div className="landing-page-preview">
            <h3 className="flex justify-center">{translate('Preview')}</h3>
            <div className="preview-container" style={{ border: '1px solid #ccc', height: '500px', overflow: 'auto' }}>
                <TemplateComponent
                    merchantId={merchantId}
                    messageApi={messageApi}
                    portalOptionSettingViewModel={portalOptionSettingViewModel}
                    portalStyleSettings={portalStyleSettings}
                    config={config}
                    translate={translate}
                />
            </div>
        </div>
    );
}

export default LandingPagePreview;