import { IBeAssistantRequest } from "@/interfaces/Requests/Merchant";
import { IMerchantAssistant, IMerchantAssistantViewModel, IOperationResultT } from "@/interfaces/Responses/Responses";
import { useAcceptToBeAssistantApi, useGetAssistanceInvitationsApi, useRejectToBeAssistantApi } from "@/lib/api/merchants";
import { useRefreshTokenApi } from "@/lib/api/token";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Empty, Space, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";

const MyAssistantInvitesList: React.FC = () => {
    const { messageApi, setIsLogin } = useContext(GlobalContext);
    const { translate } = useContext(TranslationContext);
    const [merchantAssistants, setMerchantAssistants] = useState<IMerchantAssistantViewModel[]>([]);

    const { mutate: getInvitations } = useMutation(useGetAssistanceInvitationsApi, {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setMerchantAssistants(response.result!);
            }
        }
    });

    const { mutate: refreshToken } = useMutation(useRefreshTokenApi, {
        onSuccess: (response: IOperationResultT<string>) => {
            if (response.isSuccess && response.result) {
                localStorage.setItem("JWTToken", response.result);
                setIsLogin(true);
                messageApi.success(translate('Token refreshed successfully'));
            } else {
                messageApi.error(translate('Failed to refresh token'));
            }
        },
        onError: () => {
            messageApi.error(translate('Failed to refresh token'));
        }
    });

    const { mutate: acceptInvitation } = useMutation(async (request: IBeAssistantRequest) => await useAcceptToBeAssistantApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Invitation accepted successfully'));
                refreshToken(); // 調用 refreshToken 來獲取新的 token
                getInvitations(undefined);
            } else {
                messageApi.error(translate('Failed to accept invitation'));
            }
        }
    });

    const { mutate: rejectInvitation } = useMutation(async (request: IBeAssistantRequest) => await useRejectToBeAssistantApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Invitation rejected successfully'));
                getInvitations(undefined);
            } else {
                messageApi.error(translate('Failed to reject invitation'));
            }
        }
    });

    useEffect(() => {
        getInvitations(undefined);
    }, []);

    const columns = [
        {
            title: translate('Merchant ID'),
            dataIndex: 'merchantId',
            key: 'merchantId',
            render: (merchantId: string) => BigInt(merchantId).toString(),
        },
        {
            title: translate('Merchant Name'),
            dataIndex: 'merchantName',
            key: 'merchantName',
        },
        {
            title: translate('CreatedTime'),
            dataIndex: 'createTime',
            key: 'createTime',
            render: (date: Date) => new Date(date).toLocaleDateString(),
        },
        {
            title: translate('Status'),
            dataIndex: 'isAccepted',
            key: 'isAccepted',
            render: (isAccepted: boolean) => isAccepted ? translate('Accepted') : translate('Pending'),
        },
        {
            title: translate('Operation'),
            key: 'actions',
            render: (text: string, record: IMerchantAssistant) => (
                <Space size="small">
                    {!record.isAccepted && (
                        <>
                            <Button type="primary" onClick={() => acceptInvitation({ merchantId: record.merchantId })}>
                                {translate('Accept')}
                            </Button>
                            <Button danger onClick={() => rejectInvitation({ merchantId: record.merchantId })}>
                                {translate('Reject')}
                            </Button>
                        </>
                    )}
                </Space>
            ),
        },
    ];

    if (merchantAssistants.length === 0) {
        return <Empty description={translate('No data')} />;
    }

    return (
        <div>
            <Table
                dataSource={merchantAssistants}
                columns={columns}
                rowKey="id"
                pagination={{ pageSize: 10, position: ['bottomCenter'] }}
                bordered
            />
        </div>
    );
}

export default MyAssistantInvitesList;