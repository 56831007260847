import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Tabs } from "antd";
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MySalesTab from "./Components/MySalesTab";

const { TabPane } = Tabs;

const OrderManagePage: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <MySalesTab />
    );
};

export default OrderManagePage;