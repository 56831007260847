import { IChildrenProviderProps } from "@/interfaces/GlobalContextType";
import { IItemSpecDto } from "@/interfaces/Responses/Responses";
import { IItemViewModel } from "@/Templates/interfaces/templatesInterfaces";
import { UploadFile } from "antd";
import React from "react";


export interface IItemContext {
    itemViewModel: IItemViewModel;
    setItemViewModel: React.Dispatch<React.SetStateAction<IItemViewModel>>;
    itemSpecs: IItemSpecDto[];
    setItemSpecs: React.Dispatch<React.SetStateAction<IItemSpecDto[]>>;
    itemConverImageFiles: UploadFile[];
    setItemConverImageFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
}
export const ItemContext = React.createContext<IItemContext>(null!);
export const ItemContextProvider: React.FC<IChildrenProviderProps> = ({ children }): React.ReactNode => {
    const [itemViewModel, setItemViewModel] = React.useState<IItemViewModel>({
        itemConverImagePaths: [],
        itemTags: [],
        id: BigInt(0),
        name: '',
        title: '',
        briefly: '',
        description: '',
        createdDate: '',
        alterDate: '',
        isOnShelf: false,
        isDelete: false,
        minPrice: 0,
        maxPrice: 0
    })
    const [itemSpecs, setItemSpecs] = React.useState<IItemSpecDto[]>([]);
    const [itemConverImageFiles, setItemConverImageFiles] = React.useState<UploadFile[]>([]);

    return (
        <ItemContext.Provider
            value={
                {
                    itemViewModel,
                    setItemViewModel,
                    itemSpecs,
                    setItemSpecs,
                    itemConverImageFiles,
                    setItemConverImageFiles
                }
            }>
            {children}
        </ItemContext.Provider>
    );
}
