import { IConfirmMerchantApplyRequest } from "@/interfaces/Requests/Requests";
import { IOperationResult, IOperationResultT } from "@/interfaces/Responses/Responses";
import { IMerchantApply } from "@/interfaces/Responses/SystemManager";
import useAPI from "../customHooks/useAPI";

export const useGetMerchantAppliesApi = (signal?: AbortSignal) =>
    useAPI<IOperationResultT<IMerchantApply[]>>(`${process.env.BASE_API_URL}/api/SystemManager/GetMerchantApplies`, { signal });

export const useConfirmMerchatApplyApi = (request: IConfirmMerchantApplyRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/SystemManager/ConfirmMerchatApply`, {
        method: 'POST',
        body: request,
        signal
    });

export const useRejectMerchantApplyApi = (request: IConfirmMerchantApplyRequest, signal?: AbortSignal) =>
    useAPI<IOperationResult>(`${process.env.BASE_API_URL}/api/SystemManager/RejectMerchantApply`, {
        method: 'POST',
        body: request,
        signal
    });