import { ImageUseageType } from "@/enums/Enums";
import { useAlterMerchnatMainMarqueeApi, useGetMerchantMainMarqueeImagesApi, useSwitchIsEnableMainMaqueeApi } from "@/lib/api/frontendsettings";
import { GlobalContext } from "@/lib/contexts/GlobalContext";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Flex, GetProp, Row, Switch, Upload, UploadFile, UploadProps } from "antd";
import Title from "antd/es/typography/Title";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { v4 as uuidv4 } from 'uuid';
import { PortalSettingPageContext } from "../Contexts/PortalSettingPageContext";

const MarqueeImagesEditor: React.FC = () => {
    type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
    const { merchantId, messageApi, merchantPortalOptionSetting, getMerchantPortalOptionSetting } = useContext(GlobalContext);
    const { supportedLanguages, translate } = useContext(TranslationContext);
    const { abortController } = useContext(PortalSettingPageContext);
    const { data: marqueeImagesFromApi, mutate, isLoading: getMerchantMarqueeLoading } = useMutation(async () => await useGetMerchantMainMarqueeImagesApi(abortController.current.signal));
    const { mutate: alterMutate, isLoading: alterMerchantMarqueeLoading } = useMutation(async (request: FormData) => await useAlterMerchnatMainMarqueeApi(request), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                messageApi.success(translate('Operation success'));
                mutate();
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const { mutate: switchMutate, isLoading } = useMutation(async (enabled: boolean) => await useSwitchIsEnableMainMaqueeApi(enabled), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                getMerchantPortalOptionSetting.mutate({ merchantId: merchantId! });
                messageApi.success(translate('Operation success'));
            }
            else {
                messageApi.error(translate(response.message || 'Operation failed'));
            }
        }
    });
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const getBase64 = (file: FileType): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }
    };
    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const handleBeforeUpload: UploadProps['beforeUpload'] = (file) => {
        return false;
    };
    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    useEffect(() => {
        mutate();
    }, []);

    useEffect(() => {
        if (marqueeImagesFromApi && marqueeImagesFromApi.isSuccess && marqueeImagesFromApi.result) {
            setFileList(marqueeImagesFromApi.result.map((image) => ({ uid: uuidv4(), name: image.link, status: 'done', url: image.link })));
        }
    }, [marqueeImagesFromApi]);

    function onSave() {
        const formData = new FormData();
        fileList.forEach((file) => {
            // Done 不用處理
            if (file.status !== undefined && file.status === 'done') {
                return;
            }
            if (file.originFileObj) {
                // 获取文件扩展名
                var extension = file.name.split('.').pop();
                // 修改文件名为所需格式
                var fileName = `${ImageUseageType.MerchantMainMarquee}_${uuidv4()}.${extension}`; //ItemCover_x.jpg //ItemCover_x.png
                // 创建一个新的文件对象，将原始文件对象和新文件名一起存入请求中
                const newFile = new File([file.originFileObj], fileName, { type: file.type });
                formData.append('Images', newFile);
            }
        });

        var needDeletedImages = marqueeImagesFromApi?.result?.filter((image) => !fileList.some((file) => file.name === image.link));
        if (needDeletedImages && needDeletedImages.length > 0) {
            needDeletedImages.forEach((image) => {
                formData.append('NeedDeletedIds', image.id.toString());
            });
        }
        alterMutate(formData);
    }

    function saveBtnShows(): boolean {
        var needDeleted = marqueeImagesFromApi?.result?.filter((image) => !fileList.some((file) => file.name === image.link)).length;
        return (needDeleted && needDeleted > 0) || fileList.filter(r => r.status !== 'done').length > 0;
    }
    return (
        <>
            <Title level={5}>{translate('Enable')}</Title>
            <Switch
                loading={isLoading || getMerchantPortalOptionSetting.isLoading}
                checked={merchantPortalOptionSetting?.isEnableMainMarquee}
                onChange={(checked) => switchMutate(checked)}
            />
            <Row>
                <Col xs={{ span: 24 }} style={{ marginTop: '30px', marginBottom: '30px' }}>
                    <Upload
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        beforeUpload={handleBeforeUpload}
                    >
                        {fileList.length >= 4 ? null : uploadButton}
                    </Upload>
                    <Flex justify="center">
                        <Button
                            type="primary"
                            style={{
                                width: '75%',
                                display: saveBtnShows() ? 'block' : 'none'
                            }}
                            loading={alterMerchantMarqueeLoading}
                            onClick={() => {
                                onSave();
                            }}>
                            {translate('Save')}
                        </Button>
                    </Flex>
                </Col>
            </Row>
        </>
    );
}

export default MarqueeImagesEditor;