import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { Button, Flex } from 'antd';
import html2canvas from 'html2canvas';
import React, { useContext, useRef, useState } from 'react';
import CodeEditComponent from './CodeEditComponent';

const ScreenCapture: React.FC = () => {
    const { translate } = useContext(TranslationContext);
    const [code, setCode] = useState<string>(``);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const componentRef = useRef<HTMLDivElement>(null);

    const captureComponent = () => {
        if (componentRef.current) {
            html2canvas(componentRef.current).then(canvas => {
                setImageSrc(canvas.toDataURL('image/png'));
            });
        }
    };

    return (
        <div>
            <div ref={componentRef}>
                <CodeEditComponent onCodeChange={setCode} />
            </div>
            <Flex justify="center">
                <Button onClick={captureComponent}>{translate('Capture Component')}</Button>
            </Flex>
            {imageSrc && (
                <div style={{ width: '100%', marginTop: '16px' }}>
                    <Flex justify="center">
                        <h3>Captured Image:</h3>
                    </Flex>
                    <Flex justify="center">
                        <img src={imageSrc} alt="Captured Screenshot" />
                    </Flex>
                </div>
            )}
        </div>
    );
};

export default ScreenCapture;
