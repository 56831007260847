import { MerchantRoleEnum, MicroShopIdentityRoleConstant } from '@/constants/microShopIdentityRoleConstant';
import routes from '@/constants/routes';
import { useGetUserInfoEventCountApi } from "@/lib/api/merchants";
import { GlobalContext } from '@/lib/contexts/GlobalContext';
import { TranslationContext } from '@/lib/contexts/TranslationContext';
import { DeviceType } from '@/Templates/enums/templateEnums';
import { LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Drawer, Flex, Menu, MenuProps, MenuRef, Row, Select } from 'antd';
import Link from 'antd/es/typography/Link';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from "react-query";
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

type MenuItem = Required<MenuProps>['items'][number];

// 子组件：移动版 NavBar
const MobileNavBar = ({ visible, showDrawer, onClose, logo, isLogin, userInfoCount, renderAuthButtons, menuItems, current, handleClick, languaguesSelectorUI, divRef, menuRef, navigate, translate }) => (
    <div ref={divRef} className={`pb-1 w-full flex items-center justify-start transition-all duration-300 pointer-events-auto`} style={{ backgroundColor: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)' }}>
        <div className="w-15 flex items-center justify-center">
            <Button onClick={showDrawer} className={`transition-all duration-300`}>
                {React.createElement(visible ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </Button>
        </div>
        <Drawer
            placement="left"
            width="100%"
            onClose={onClose}
            open={visible}
            title={
                <Flex justify='space-between' style={{ width: '100%' }}>
                    <img
                        src={logo}
                        alt="Logo"
                        style={{ maxHeight: '30px', width: 'auto', cursor: 'pointer' }}
                        onClick={() => window.location.href = 'home'}
                    />
                    {renderAuthButtons()}
                </Flex>
            }
        >
            <>
                <Menu
                    ref={menuRef}
                    key={uuidv4()}
                    onClick={handleClick}
                    className="w-full"
                    style={{ borderRightWidth: '0px' }}
                    mode="inline"
                    items={menuItems}
                    selectedKeys={[current]} // 设置选中的菜单项
                />
                <Flex justify='center' align='end'>
                    {languaguesSelectorUI}
                </Flex>
            </>
        </Drawer>
    </div>
);

// 子组件：桌面版 NavBar
const DesktopNavBar = ({ logo, isLogin, userInfoCount, renderAuthButtons, menuItems, current, handleClick, languaguesSelectorUI, menuRef, navigate, translate, onClose }) => (
    <Row className="border border-solid border-border-color border-1" justify="space-between" align="middle" style={{ backgroundColor: 'white' }}>
        <Col span={18}>
            <Menu
                ref={menuRef}
                key={uuidv4()}
                onClick={handleClick}
                className="w-full border-0"
                mode="horizontal"
                items={menuItems}
                selectedKeys={[current]} // 设置选中的菜单项
            />
        </Col>
        <Col span={6}>
            <Flex justify="end" align="center">
                {languaguesSelectorUI}
                {renderAuthButtons()}
            </Flex>
        </Col>
    </Row>
);

const NavBar: React.FC = React.memo(() => {
    const menuRef = useRef<MenuRef>(null);
    const divRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const {
        messageApi,
        removeMerchantIdAggregate,
        hasMerchant,
        merchantId,
        isHideNavBar,
        isLogin,
        setIsLogin,
        setNavBarHeight,
        deviceType,
        setDeviceType,
        identityRoles,
        merchantPermissionRoles
    } = useContext(GlobalContext);
    const { translate, supportedLanguages, i18nLanguage, seti18nLanguage } = useContext(TranslationContext);
    const [visible, setVisible] = useState(false);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [current, setCurrent] = useState<string>(location.pathname);
    const [logo, setLogo] = useState<string>('https://storage.googleapis.com/microshop-images-bucket/DALL_E_Logo.jpg');
    const [userInfoCount, setUserInfoCount] = useState<number>(0);

    const { mutate: getUserInfoCount } = useMutation(async () => await useGetUserInfoEventCountApi(), {
        onSuccess: (response) => {
            if (response.isSuccess) {
                setUserInfoCount(response.result!);
            }
        }
    });

    useEffect(() => {
        getUserInfoCount();
    }, []);

    const handleClick = useCallback((e: any) => {
        setCurrent(e.key);
        onClose();
    }, []);

    const showDrawer = useCallback(() => {
        setVisible(true);
    }, []);

    const onClose = useCallback(() => {
        setVisible(false);
    }, []);

    const setNavBarHeightFunc = useCallback(() => {
        if ((deviceType === DeviceType.Mobile) && divRef.current?.offsetHeight === undefined) {
            return;
        }
        if ((deviceType !== DeviceType.Mobile) && menuRef.current?.menu?.list === undefined) {
            return;
        }
        const height = (deviceType !== DeviceType.Mobile) ? menuRef.current!.menu?.list.offsetHeight : divRef.current?.offsetHeight;
        if (height === undefined) {
            return;
        }
        setNavBarHeight(height + 10);
    }, [deviceType, setNavBarHeight]);

    const languaguesSelectorUI = useMemo(() => {
        const uI = supportedLanguages.map((lang) => (
            <Select.Option key={translate(lang)} value={lang} >
                {translate(lang)}
            </Select.Option>
        ));
        return (
            <Select
                style={{ width: 'auto' }}
                value={translate(i18nLanguage)}
                onChange={(value: string) => seti18nLanguage(value)}
            >
                {uI}
            </Select>
        );
    }, [supportedLanguages, i18nLanguage, translate, seti18nLanguage]);

    const Logout = useCallback(() => {
        localStorage.removeItem('JWTToken');
        removeMerchantIdAggregate();
        setIsLogin(false);
        messageApi.success(translate('Operation success'));
        navigate(`/login`);
        onClose();
    }, [removeMerchantIdAggregate, setIsLogin, messageApi, translate, navigate, onClose]);

    const Login = useCallback(() => {
        navigate(`/login`);
        onClose();
    }, [navigate, onClose]);

    const renderAuthButtons = useCallback(() => (
        isLogin ? (
            <Flex align="center">
                <Badge count={userInfoCount} overflowCount={99} showZero={false}>
                    <UserOutlined
                        style={{ fontSize: '24px', cursor: 'pointer' }}
                        onClick={() => {
                            navigate('/user-info');
                            onClose();
                        }}
                    />
                </Badge>
                <Button onClick={Logout} style={{ marginLeft: '10px' }}>
                    <LogoutOutlined />
                    {translate('Logout')}
                </Button>
            </Flex>
        ) : (
            <Button onClick={Login} style={{ marginLeft: '10px' }}>
                <LogoutOutlined />
                {translate('Login')}
            </Button>
        )
    ), [isLogin, userInfoCount, navigate, onClose, Logout, Login, translate]);

    const memoizedNavigate = useCallback((path: string) => navigate(path), [navigate]);

    const memoizedMenuItems = useMemo(() => {
        return Object.keys(routes)
            .map((key) => {
                const route = routes[key];

                // 檢查是否忽略此菜單項
                if (route.navbarIgnore) return null;

                // 檢查用戶是否擁有該菜單項所需的身份角色，如果是系統管理員，則全部可操作
                if (route.identityRoles && route.identityRoles.length > 0) {
                    const hasRequiredRole = route.identityRoles.some((role) => identityRoles?.includes(role)) || identityRoles?.includes(MicroShopIdentityRoleConstant.SystemAdmin);
                    if (!hasRequiredRole) return null;
                }

                // 檢查用戶是否擁有該菜單項所需的商戶權限，如果是店主，則全部可操作
                if (route.merchantPermissionRoles && route.merchantPermissionRoles.length > 0) {
                    const hasRequiredPermission = route.merchantPermissionRoles.some((role) => merchantPermissionRoles?.includes(role)) || merchantPermissionRoles?.includes(MerchantRoleEnum.Owner);
                    if (!hasRequiredPermission) return null;
                }

                // 檢查是否需要忽略商戶選擇，但當前沒有商戶的情況
                if (!route.ignoreMerchantSeleted && !hasMerchant) return null;

                // 如果所有條件都符合，則返回菜單項配置
                return {
                    index: route.index || 0, // 設置菜單項的索引，如果 route.index 不存在則默認為 0
                    key: route.path, // 菜單項的 key，通常用於唯一識別
                    label: (
                        // 使用 Ant Design 的 Link 元素來顯示菜單項，並在點擊時導航到對應的路由
                        <Link onClick={() => memoizedNavigate(route.path)}>
                            {translate(key.replace('Page', ''))} {/* 使用 translate 函數翻譯菜單項名稱 */}
                        </Link>
                    ),
                };
            })
            // 過濾掉所有為 null 的菜單項，僅保留有效的菜單項
            .filter(Boolean) as MenuItem[];
    }, [hasMerchant, i18nLanguage, identityRoles, merchantPermissionRoles, translate, memoizedNavigate]);



    useEffect(() => {
        setMenuItems(memoizedMenuItems);
    }, [memoizedMenuItems]);

    useEffect(() => {
        setNavBarHeightFunc();
    }, [deviceType, menuItems, setNavBarHeightFunc]);

    useEffect(() => {
        setCurrent(location.pathname); // 更新 current 状态为当前路径
    }, [location.pathname]);

    if (!isLogin) {
        return null;
    }

    if (isHideNavBar) {
        return null;
    }

    return (
        <div className={`fixed transition-all duration-300 border border-solid border-border-color lg:border-0 w-full z-50 border-t-0 ${deviceType === DeviceType.Mobile ? 'pointer-events-none' : ''}`}>
            {deviceType === DeviceType.Mobile ? (
                <MobileNavBar
                    visible={visible}
                    showDrawer={showDrawer}
                    onClose={onClose}
                    logo={logo}
                    isLogin={isLogin}
                    userInfoCount={userInfoCount}
                    renderAuthButtons={renderAuthButtons}
                    menuItems={menuItems}
                    current={current}
                    handleClick={handleClick}
                    languaguesSelectorUI={languaguesSelectorUI}
                    divRef={divRef}
                    menuRef={menuRef}
                    navigate={navigate}
                    translate={translate}
                />
            ) : (
                <DesktopNavBar
                    logo={logo}
                    isLogin={isLogin}
                    userInfoCount={userInfoCount}
                    renderAuthButtons={renderAuthButtons}
                    menuItems={menuItems}
                    current={current}
                    handleClick={handleClick}
                    languaguesSelectorUI={languaguesSelectorUI}
                    menuRef={menuRef}
                    navigate={navigate}
                    translate={translate}
                    onClose={onClose}
                />
            )}
        </div>
    );
});

export default NavBar;
