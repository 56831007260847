import { IMemberInfoViewModel } from "@/interfaces/Responses/Responses";
import { TranslationContext } from "@/lib/contexts/TranslationContext";
import { Button, Card, Flex, Space, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
const { Text, Title } = Typography;

export interface IMemberDetailProps {
    memberVM: IMemberInfoViewModel | undefined;
}
export interface IMemberDetailRef {
    onOpen: () => void;
    onClose: () => void;
}
const MemberDetail = React.forwardRef((props: IMemberDetailProps, ref: React.ForwardedRef<IMemberDetailRef>) => {
    const { memberVM } = props;
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();

    const handleOrdersClick = () => {
        navigate(`/orderManage?memberId=${memberVM?.id}`);
    };

    if (!memberVM) {
        return null;
    }

    return (
        <>
            <Card title={translate('Member Detail')} style={{ marginBottom: '10px' }}>
                <Space direction="vertical" size="large">
                    <Space direction="horizontal">
                        <Text strong>{translate('Member ID')}:</Text>
                        <Text>{memberVM.id.toString()}</Text>
                    </Space>
                    <Space direction="horizontal">
                        <Text strong>{translate('UserName')}:</Text>
                        <Text>{memberVM.userName}</Text>
                    </Space>
                    <Space direction="horizontal">
                        <Text strong>{translate('Email')}:</Text>
                        <Text>{memberVM.email}</Text>
                    </Space>
                    <Space direction="horizontal">
                        <Text strong>{translate('Phone number')}:</Text>
                        <Text>{memberVM.phoneNumber}</Text>
                    </Space>
                    <Space direction="horizontal">
                        <Text strong>{translate('Registration Date')}:</Text>
                        <Text>{memberVM.registDate.toLocaleString()}</Text> {/* Format date as needed */}
                    </Space>
                    <Space direction="horizontal">
                        <Text strong>{translate('Birthday')}:</Text>
                        <Text>{memberVM.birthday.toLocaleString()}</Text> {/* Format date as needed */}
                    </Space>
                    <Space direction="horizontal">
                        <Text strong>{translate('Gender')}:</Text>
                        <Text>{translate(memberVM.gender)}</Text> {/* Display gender enum value */}
                    </Space>
                    <Space direction="horizontal">
                        <Text strong>{translate('Banned')}:</Text>
                        <Text>{memberVM.isBanned ? translate("Yes") : translate("No")}</Text>
                    </Space>
                </Space>
                <Flex
                    justify="center"
                >
                    <Button type="primary" onClick={handleOrdersClick}>{translate('View Orders')}</Button>
                </Flex>
            </Card>
            <Card title={translate('Member') + ' ' + translate('Level')}>
                Member Level
            </Card >
        </>
    );
});

export default MemberDetail;